@import url(../../styles/common.css);

header {
  padding-top: 12px;
  width: 100%;
  /* outline: 1px solid rgba(38, 44, 110, 0.1); */
  position: absolute;
  top: 0;
  z-index: 99;
  transition: all 0.5s;
  background-color: #003cc5 !important;
}
.sticky {
  transform: translateY(0);
}
header .logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hidden {
  transform: translateY(-100%);
}
.container .header-right {
  float: right;
}
.navLinks ul {
  display: flex;
  gap: 50px;
  align-items: center;
}
.menuLinkAndOwner {
  display: none;
  align-items: center;
  gap: 16px;
}
.menuLinkAndOwner .mobile img {
  max-width: 16px;
}
.menuLinkAndOwner .mobile span {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  color: rgba(38, 44, 110, 0.8);
}
.ownerExpand {
  display: flex;
  align-items: center;
}
.navLinks li:after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #eb6d47;
  transition: width 0.5s;
}
.navLinks li:hover::after {
  width: 100%;
}
.logo img {
  display: block;
  width: 145px;
}

.navLinks ul li a {
  font-size: 18px;
  line-height: 30px;
  color: white;
  display: inline-block;
  width: 100%;
}

.navLinks .logo {
  display: none;
}
.logo a + img {
  display: none;
}
.navLinks ul li {
  position: relative;
  color: #212445;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
}
.navLinks ul li input {
  position: relative;
  height: 48px;
  padding: 14.5px 15px 14.5px 16px;
  width: 298px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: rgba(70, 72, 104, 0.8);
  outline: none;
  border: 1px solid #8e93d7;
}
/* .navLinks ul li input:hover{
  padding: 14.5px 15px 14.5px 16px;
  width: 298px;
  border-radius: 16px;
  height: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: rgba(70, 72, 104, 0.8);
  outline: none;
  border: 1px solid #8e93d7;
} */
.carNumFetch {
  display: flex;
  align-items: center;
}
.navLinks ul li .exploreBtn {
  /* position: absolute; */
  right: 0;
  /* padding: 14px 16px; */
  color: #ffffff;
  /* background: #EB6D47; */
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
}
.navLinks ul li .exploreBtn button {
  padding: 14px 16px;
  position: absolute;
  right: 0;
  border-radius: 8px;
  border: none;
  background: #eb6d47;
  color: #ffffff;
  height: 47px;
  cursor: pointer;
  font-family: 'matter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
}
.headerDropdown {
  position: relative;
  display: inline-block;
}
.headerDropdownList:hover::after {
  width: 0 !important;
}
.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
  padding: 8px 0;
  z-index: 1;
  border-radius: 8px;
  top: 31px;
  left: -2px;
}
.dropdownContent p {
  cursor: pointer;
  color: #262c6e;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 24px;
}
.dropdownContent p a:hover {
  color: #eb6d47;
}

.dropdownContent p:hover {
  /* background-color: red; */
}
.headerDropdown:hover .dropdownContent {
  display: block;
}

.open {
  height: 140px !important;
}

.overlay {
  height: 0%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 56px;
  left: 0;
  /* background-color: rgb(0,0,0); */
  /* background-color: rgba(0,0,0, 0.9); */
  background: #ffffff;
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  /* top: 25%; */
  width: 100%;
  text-align: center;
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
}

.overlay a {
  /* padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block; */
  color: #262c6e;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  transition: 0.3s;
  padding: 8px 24px;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay {
    overflow-y: auto;
  }
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
  .menuLinkAndOwner .mobile span {
    font-size: 14px;
    font-weight: 500;
  }
}

@media (width: 769px) {
  .tabListMobile {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .company-logo {
    width: 120px;
  }
  .barsPhase3 {
    padding: 10px 6px;
  }
  .closeIconPhase3 {
    padding: 10px 6px;
  }
  .overlay a {
    width: 100%;
    justify-content: flex-start;
    text-align: left;
  }
  .overlay a:hover,
  .overlay a:focus {
    color: #262c6e;
  }
  header {
    padding: 8px 0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  }

  .logo a img {
    width: 120px;
  }

  .logo img:last-child {
    display: block;

    /* change made on new logo */
    width: 40px;
  }

  .logo img:first-child {
    width: 120px;
  }
  .menuLinkAndOwner {
    display: flex;
  }
  .navLinks .logo {
    display: flex;
  }
  header .container {
    flex-direction: column;
    align-items: flex-start;
  }
  .navLinks ul {
    flex-direction: column;
    padding: 50px 24px 0;
  }
  header .logo {
    padding: 0 24px;
    width: 100%;
  }
  .navLinks {
    display: none;
  }
  header .container {
    padding: 0;
  }
  .active .navLinks {
    transform: translateX(0);
  }
  .headerDropdownList {
    display: none;
  }
  .navLinks ul li a {
    font-size: 18px;
    line-height: 30px;
    color: white;
    display: inline-block;
    width: 100%;
  }
}