.contact-us {
  /* background: linear-gradient(
    180deg,
    rgba(255, 129, 92, 0) 0%,
    rgba(255, 129, 92, 0.12) 100%
  ); */
  /* background-color: #eff2ff; */
  background-color: white;
  padding: 90px 0 50px;
}
.contactus-wrap {
  display: flex;
  justify-content: space-between;
}
.contact-us-info {
  margin-top: 5rem;
}
.contact-us-description {
  margin-top: 1rem;
  display: flex;
  gap: 20px;
}
.contact-us-description-left {
  width: 400px;
}
.contact-us-description-right {
  width: 400px;
}
.contact-us-description-right p {
  /* padding-top: 0px; */
}
.contactus-left-container {
  width: 100%;
}
.contactus-right-container {
  /* width: 50%; */
}
.contactus-right-container .contactus-card {
  border-radius: 15px !important;
  padding: 1.25rem;
  background: #eff3ff;
  width: 350px;
}
.contactUs .commonHeading {
  max-width: 100%;
  width: 700px;
  margin-bottom: 46px;
}
.contactUs .commonHeading h2 {
  color: #262c6e;
}
.contactUsContent {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.contactUsLink {
  background-color: #ffffff;
  border-radius: 17px;
  flex-basis: 31%;
  width: 100%;
  position: relative;
}
.contactUsLink::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 18px;
  border: 1px solid transparent;
  background: linear-gradient(
    to right,
    rgba(255, 129, 92, 0.5),
    rgba(245, 182, 163, 0.5)
  );
  background: -moz-linear-gradient(
    to left,
    rgba(255, 129, 92, 0.5),
    rgba(245, 182, 163, 0.5)
  );
  background: -o-linear-gradient(to right, #ff815c 50%, #f5b6a3 50%);
  -webkit-mask: linear-gradient(rgba(255, 255, 255, 1) 0 0) padding-box,
    linear-gradient(rgba(255, 255, 255, 1) 0 0);
  -webkit-mask: -o-linear-gradient(rgba(255, 255, 255, 1) 0 0) padding-box,
    linear-gradient(rgba(255, 255, 255, 1) 0 0);
  -webkit-mask: -o-linear-gradient(rgba(255, 255, 255, 1) 0 0) padding-box,
    linear-gradient(rgba(255, 255, 255, 1) 0 0);
  -webkit-mask-composite: destination-out;
}
.contactUsContent a {
  color: #464868;
  padding: 32px 56px 46px 32px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  position: relative;
  display: block;
  z-index: 9;
  min-height: 64px;
  height: 98%;
  border-radius: 16px;
  width: 99%;
  margin: auto;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: #fff;
}
.contactUsContent a::after {
  content: url(../../assets/images/home/arrowNextBlack.svg);
  position: absolute;
  right: 24px;
  top: 32px;
  height: 32px;
  transition: all 0.5s;
}
.contactUsLink:hover a {
  color: #eb6d47;
  padding: 31px 54px 45px 30px;
}
.contactUsLink:hover a:after {
  content: url(../../assets/images/home/arrowNext.svg);
  transform: translate3d(5px, -5px, 5px);
}
.contactUsLink:hover a {
  width: 98%;
  height: 96%;
  margin-top: 2px;
  margin-bottom: 2px;
}
.contactUsLink:hover::after {
  border: 2px solid #eb6d47;
}
@media (max-width: 992px) {
  .contactUsContent {
    justify-content: flex-start;
  }
  .contactus-wrap {
    flex-direction: column;
  }
  .contactus-right-container {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .contact-us {
    padding: 56px 20px;
  }
  .contact-us .commonHeading {
    font-family: 'matterSemiBold';
    font-size: 34px;
    padding-left: 10px;
    margin-bottom: 0px;
  }
  .contactus-left-container span {
    padding-left: 10px;
  }
  .contactus-left-container p {
    line-height: 25px;
    padding-left: 0px !important;
  }
  .contact-us-info {
    margin-top: 1rem;
  }
  .contact-us-description {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .contact-us-description h6 {
    /* padding-left: 10px; */
  }
  .contact-us-description p {
    padding-left: 0px;
    padding-top: 0px;
  }
  .contactus-wrap {
    flex-direction: column;
  }
  .contactus-right-container {
    margin-top: 1rem;
    width: 100%;
  }
  .contact-us-description-left {
    width: 100%;
  }
  .contact-us-description-right {
    width: 100%;
  }
  .contactus-right-container .contactus-card {
    width: 100%;
  }
  .contactus-card h2 {
    font-family: 'matterSemiBold';
    font-size: 34px;
  }
  .contactus-card p {
    padding-left: 0px !important;
  }
  .contactUsLink:hover a {
    color: #464868;
    padding: inherit;
  }
  .contactUsLink:hover a:after {
    content: url(../../assets/images/home/arrowNextBlack.svg);
    transform: inherit;
  }
  .contactUsContent {
    flex-wrap: wrap;
  }
  .contact-us-description-left p {
    width: 100%;
  }

  .contact-us-description-right p {
    width: 100%;
  }
  .contactUsLink:hover::after {
    border: 1px solid transparent;
  }
  .contactUsLink:hover a {
    padding: 31px 20.5px 39px;
  }
  .contactUsLink a:after {
    transition: none;
  }
  .contactUsLink:hover a:after {
    transition: none;
    transform: translate3d(0px, 0px, 0px);
    right: 20px;
    top: 27px;
  }
  .contactUsLink {
    max-width: 100%;
    flex-basis: 100%;
  }
  .contactUs {
    padding: 56px 0;
  }
  .contactUsContent a {
    padding: 32px 19px 40px 24px;
    font-size: 16px;
    line-height: 24px;
  }
}

.MuiList-root-MuiMenu-list {
  background-color: red !important;
}
