.page-wrapper {
  max-width: 768px; /* Reflects a common tablet size */
  width: 100%;
  margin: 0 auto; /* Centers the content horizontally */
  font-family: "Manrope", sans-serif;
  background-color: #f1f8fa;
  > p {
    padding: 0;
  }
}

.header {
  position: fixed; /* Make the header fixed at the top */
  top: 0;
  left: 50%; /* Set left to 50% to help with centering */
  transform: translateX(
    -50%
  ); /* Translate back by 50% to perfectly center the fixed element */
  max-width: 768px; /* Restrict header to tablet width */
  width: 100%;
  background-color: #003cc5; /* Set the background color */
  padding: 4px 2px; /* Add padding to ensure spacing around */
  z-index: 1000; /* Keep it above other elements */
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  width: 100%; /* Container width should take full header width */
  margin: 0 auto; /* Centers the content within the header */
}

.header__logo {
  /* Center the logo within its container */
}

.header__logo-img {
  width: 110px;
  height: auto;
}
.header__number {
  padding: 0px 4px 0px 0px;
  font-weight: 400;
  > p {
    color: #fff;
    padding: 0;
    font-size: 18px;
    font-family: "manropeMedium";
    font-weight: 400;
  }
}
.summary-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 40px; /* Space for visibility */
}

/* Blue Background Section */
.summary-card__background {
  height: 345px;
  width: 100%;
  background-color: #003cc5;
  position: relative;
}

.summary-card {
  background-color: #fff;
  border-radius: 20px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 110px;
}

/* Summary Card Content */
.summary-card__content {
  text-align: center;
  padding-top: 20px;
  > p {
    padding: 0;
  }
}

/* Customer Information */
.summary-card__customer-name {
  font-family: "Manrope", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  color: #1a1a1a;
}

.summary-card__customer-info {
  color: #6b7280;
  margin-top: 8px;
  font-size: 1rem;
  font-weight: 500;
}

.summary-card__customer-contact {
  color: #003cc5;
  font-size: 18px !important;
  font-weight: 500;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary-card__full-details {
  background: none;
  border: none;
  border-radius: 8px;
  color: #003cc5; /* Same color as the contact details */
  cursor: pointer;
  text-decoration: none; /* Make it look like a link */
  font-family: "Manrope", sans-serif; /* Match the font-family */
  font-size: 10px; /* Match the font-size of the contact details */
  padding: 4px 8px; /* Minimal padding to create space between the button and contact */
  outline: none; /* Remove the button outline */
  transition: color 0.2s ease; /* Smooth color change on hover */
  margin-top: 4px;
}

/* Divider */
.summary-card__divider {
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
  margin: 20px 0;
}

/* Report Details */
.summary-card__details {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Spacing between rows */
  margin-top: 20px;
  padding: 0 10px;
}

.detail-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
}

.detail-row-report {
  display: flex;
  justify-content: space-between;
}

.detail-row-overall {
  display: flex;
  justify-content: space-between;
}

.detail-row-market.full-width {
  grid-column: 1 / -1; /* Span across both columns */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail-label {
  color: #000;
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.detail-value {
  color: #111827;
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 4px;
}

.badge-highlight {
  background-color: #ccf0e1;
  color: #065f46;
  padding: 5px 10px;
  border-radius: 5px;
}

.overall-rating strong {
  color: #f59e0b;
  font-size: 1.8rem;
}

.badge-no {
  background-color: #fee2e2;
  color: #b91c1c;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: 800;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .summary-card {
    width: 85%;
    padding: 15px;
  }

  .summary-card__customer-name {
    font-size: 1.6rem;
  }

  .summary-card__customer-info,
  .summary-card__customer-contact {
    font-size: 1rem;
  }

  .detail-label,
  .detail-value {
    font-size: 0.9rem;
  }

  .overall-rating strong {
    font-size: 1.5rem;
  }
}

/* Container for the entire inspection summary and interpretation sections */
.inspection-summary-container {
  max-width: 768px; /* Keeps consistency with the tablet size */
  margin: 160px 0px 0px auto; /* Add some space from the previous card and center the container */
  padding: 0 20px;
}

/* Inspection Summary Section */
.inspection-summary {
  text-align: center;
  margin-bottom: 30px;
  padding-top: 20px;
  > p {
    padding-top: 0 !important;
  }
}

.inspection-summary__title {
  font-family: "Manrope", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #1a1a1a;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.inspection-summary__checkpoints {
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #4b5563;
  margin-top: 8px;
}

/* Interpretations Section */
.interpretations {
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px;
  margin: 20px auto;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  > p {
    padding: 0;
  }
}

.interpretations__title {
  font-family: "Manrope", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 10px;
}

.interpretations__text {
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #4b5563;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .inspection-summary__title {
    font-size: 1.2rem;
  }

  .inspection-summary__checkpoints {
    font-size: 0.9rem;
  }

  .interpretations__title {
    font-size: 1.1rem;
  }

  .interpretations__text {
    font-size: 0.9rem;
  }
}

/* Container for the entire vehicle information section */
.vehicle-information-container {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 0px 20px;
  margin: 10px 10px;
  max-width: 768px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06); */
}

/* Title */
.vehicle-information__title {
  font-family: "Manrope", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #003cc5;
  margin-bottom: 20px;
  text-align: left;
}

/* Grid Layout for Vehicle Information */
.vehicle-information__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for tablet view */
  gap: 20px;
  padding-bottom: 10px;
}

/* Each Vehicle Info Item */
.vehicle-info-item {
  display: flex;
  flex-direction: column;
}

/* Label Styling */
.vehicle-info-label {
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #4b5563;
}

/* Value Styling */
.vehicle-info-value {
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #1a1a1a;
}

/* Highlighted Value for Emphasis */
.vehicle-info-value.highlighted {
  color: #d32f2f; /* Red color for highlighting important info */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .vehicle-information__grid {
    grid-template-columns: repeat(3, 1fr); /* Two columns for mobile view */
    gap: 15px;
  }

  .vehicle-information__title {
    font-size: 1.2rem;
  }

  .vehicle-info-label,
  .vehicle-info-value {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .vehicle-information__grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Single column for smaller devices */
    gap: 10px;
  }
}

/* Container for the entire key information section */
.key-information-container {
  background-color: #ffffff;
  position: relative;
  border-radius: 16px;
  padding: 20px;
  margin: 20px 20px;
  max-width: 768px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Title */
.key-information__title {
  font-family: "Manrope", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #003cc5;
  margin-bottom: 20px;
  text-align: left;
}

/* Grid Layout for Key Information */
.key-information__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for tablet view */
  gap: 20px;
}

/* Each Key Info Item */
.key-info-item {
  display: flex;
  flex-direction: column;
}

/* Label Styling */
.key-info-label {
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #4b5563;
}

/* Value Styling */
.key-info-value {
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 800;
  color: #1a1a1a;
}

/* Highlighted Values */
.key-info-value.highlighted-red {
  color: #d32f2f; /* Red color for NO or warning conditions */
}

.key-info-value.highlighted-green {
  color: #388e3c; /* Green color for GOOD condition */
}

.key-info-value.highlighted-yellow {
  color: #fbc02d; /* Yellow color for AVERAGE condition */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .key-information__grid {
    grid-template-columns: repeat(3, 1fr); /* Two columns for mobile view */
    gap: 15px;
  }

  .key-information__title {
    font-size: 1.2rem;
  }

  .key-info-label,
  .key-info-value {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .key-information__grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Single column for smaller devices */
    gap: 10px;
  }

  .detail-row-market {
    flex-direction: column;
  }
}

/* Container for each report card */
.report-card {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 15px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Report Card Content */
.report-card__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Report Card Title */
.report-card__title {
  font-family: "Manrope", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #1a1a1a;
}

/* Report Card Rating */
.report-card__rating {
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #4b5563;
  background-color: #e6f5f0;
  padding: 5px 10px;
  border-radius: 8px;
}

/* Show More/Less Button */
.report-card__toggle {
  background: none;
  color: #065f46;
  font-family: "Manrope", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  text-align: right;
  padding: 5px;
  margin-top: 10px;
}

/* Steps Section */
.report-card__steps {
  margin-top: 20px;
  background-color: #f9fafb;
  padding: 15px;
  border-radius: 12px;
}

/* Stepper */
.stepper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e7eb;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #6b7280;
}

.step.active {
  background-color: #003cc5;
  color: #ffffff;
}

/* Step Content */
.step-content {
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 15px;
}

/* Step Navigation */
.step-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step-button {
  background-color: #e5e7eb;
  color: #6b7280;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Manrope", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
}

.step-button:disabled {
  background-color: #cbd5e1;
  cursor: not-allowed;
}

/* Step Indicator */
.step-indicator {
  display: flex;
  gap: 5px;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e5e7eb;
}

.indicator.active {
  background-color: #003cc5;
}

/* Health Report Container */
.health-report {
  width: 100%;
  max-width: 568px;
  margin: auto; /* Center horizontally */
  padding: 16px;
}

/* Report Header */
.report-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.report-header .main-heading {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 16px;
  color: #1a1a1a;
}

.report-header .sub-heading {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #404040;
}

/* Parameters Section */
.parameters {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align children horizontally */
  gap: 20px;
  padding: 0 18px; /* Adjust padding */
  padding-bottom: 40px;
  margin-top: 20px;
}

/* Parameter Card */
.parameter-card {
  width: 100%;
  max-width: 568px;
  padding: 10px 16px;
  background-color: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Collapsible Card Show More/Less Button */
.collapsible-card .showButton {
  background-color: #e6f5f0;
  color: #049366;
  border-radius: 8px 0 8px 0;
  padding: 4px 12px;
  font-weight: 700;
  font-size: 10px;
  text-transform: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
}

/* Collapsible Card Container */
.collapsible-card {
  padding: 0px 16px;
  background-color: #fff;
  border-radius: 8px;
  transition: max-height 0.3s ease-in-out;
}

.collapsible-card-obg {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  max-width: 768px;
  margin: 20px 20px;
  transition: max-height 0.3s ease-in-out;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Collapsible Card Header */
.collapsible-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.collapsible-card-header_OBD {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: baseline;
  cursor: pointer;
  > p {
    padding: 0;
  }
}

.collapsible-card-header .title {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.collapsible-card-header .score {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  > p {
    padding: 0;
  }
}

.collapsible-card-header_OBD_errors {
  > p {
    padding: 0;
  }
}
/* Stepper Styles */
.stepper-container {
  margin-top: 16px;
}

.stepper-container .MuiStepLabel-label {
  font-size: 12px;
}

/* Step Content Container */
.step-content {
  margin-top: 16px;
  height: 350px;
}

.step-content .step-description {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  margin-bottom: 12px;
}

/* Image Gallery */
.image-gallery-container {
  margin-top: 16px;
}

/* Video Container */
.video-container {
  text-align: center;
}

.video-container iframe {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  border: none;
}

/* Fullscreen Image Container */
.fullscreen-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1300;
}

.fullscreen-image-container img {
  max-width: 90%;
  max-height: 90%;
}

/* View Full Report Button */
.view-full-report-button {
  background-color: #003cc5;
  color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .health-report,
  .parameter-card {
    width: 100%;
    padding: 16px;
  }

  .report-header {
    /* margin-top: 20px; */
    gap: 8px;
  }

  .collapsible-card .showButton {
    font-size: 9px;
  }

  .view-full-report-button {
    padding: 8px 16px;
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .health-report,
  .parameter-card {
    padding: 12px; /* Reduced padding for smaller mobile viewports */
  }

  .report-header {
    gap: 10px;
  }

  .step-content .step-description {
    font-size: 13px; /* Adjust font size for mobile */
  }

  .collapsible-card-header .title,
  .collapsible-card-header .score {
    font-size: 13px; /* Smaller font size for mobile */
  }

  .collapsible-card-header_OBD {
    flex-direction: column;
    align-items: baseline !important;
    > p {
      padding: 0;
    }
  }

  .view-full-report-button {
    padding: 8px 16px;
    font-size: 0.875rem;
  }
}

/* Stepper Container for Overflow Handling */
.stepper-container {
  margin-top: 16px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px; /* Add some space below the stepper for better separation */
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.stepper-container::-webkit-scrollbar {
  height: 6px; /* Set height for horizontal scrollbar */
}

.stepper-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar thumb color */
  border-radius: 4px;
}

.stepper-container::-webkit-scrollbar-track {
  background: transparent;
}

.stepper-container .MuiStep-root {
  min-width: 32px; /* Minimum width to ensure each step is clickable and spaced */
}

/* Adjusting overall look and feel */
.step-content {
  margin-top: 10px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  > p {
    padding: 0;
  }
}

.video-container iframe {
  width: 100%;
  max-width: 100%;
  height: 300px;
  border-radius: 8px;
  border: none;
}

/* Button adjustments for better responsiveness */
.mobile-stepper-button {
  font-size: 0.875rem;
  padding: 4px 8px;
}

.view-fault-button {
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  background-color: #d32f2f;
  color: #fff;
  border-radius: 16px;
  padding: 4px 8px;
}

.view-fault-button:hover {
  background-color: #b71c1c;
}

.fault-details {
  margin-top: 16px;
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.fault-item {
  margin-bottom: 8px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.fault-item .fault-code {
  font-weight: 600;
  color: #d32f2f;
}

.fault-item .fault-description {
  font-size: 14px;
  color: #333;
}

.estimate-card {
  width: 350px;
  flex-direction: row;
  justify-content: space-between;
}

.estimate-card-content {
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
  > p {
    padding: 0 !important;
  }
}

@media (max-width: 480px) {
  .estimate-card {
    flex-direction: column;
    margin: 16px 20px !important;
  }
  .estimate-card-content {
    flex-direction: column;
    gap: 0;
  }
  .estimate_model_content {
    padding: 0;
  }
  .estimate_icon {
    width: 82px !important;
  }
}

/* NeoInfo CSS */
.neo-info {
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;

  border-radius: 16px;
  font-family: "Manrope", sans-serif;
}

.neo-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  font-family: "Manrope", sans-serif;
}

.neo-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  font-family: "Manrope", sans-serif;
  padding: 0px !important;
}

.neo-grid-item {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  transition: transform 0.2s;
  font-family: "Manrope", sans-serif;
}

.neo-grid-item:hover {
  transform: translateY(-5px);
}

.neo-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Manrope", sans-serif;
}

/* Availability CSS */
.availability {
  padding: 20px;
  max-width: 768px;
  text-align: center;
  background-color: #003cc5;
  font-family: "Manrope", sans-serif;
}

.cities {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
  margin-top: 20px;
  font-family: "Manrope", sans-serif;
}

.city {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  gap: 4px;
  font-family: "Manrope", sans-serif;
}

/* BrandInfo CSS */
.brand-info {
  padding: 20px;
  max-width: 768px;
  text-align: center;
  background-color: #003cc5;
  color: #fff;
  font-family: "Manrope", sans-serif;
}

.trust-markers {
  display: flex;
  justify-content: space-around;
  /* gap: 30px; */
  /* margin-bottom: 5px; */
  flex-wrap: wrap;
}

.trust-markers > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 5px; */
}

.trust-markers span {
  /* margin-top: 5px; */
}

/* Download App Buttons CSS */
.download-app-buttons {
  display: flex;
  justify-content: center;
  /* gap: 20px;
  margin-top: 5px; */
  flex-wrap: wrap;
}

.download-app-buttons a img {
  transition: transform 0.2s;
}

.download-app-buttons a:hover img {
  transform: scale(1.1);
}

/* Typography Utilities (overriding oui-typography classes) */
.oui-typography--font-family-manrope {
  font-family: "Manrope", sans-serif;
}

.oui-typography--transform-none {
  text-transform: none;
}

.oui-typography--size-20 {
  font-size: 20px;
}

.oui-typography--size-14 {
  font-size: 14px;
}

.oui-typography--weight-800 {
  font-weight: 800;
}

.oui-typography--weight-600 {
  font-weight: 600;
}

.oui-typography--weight-500 {
  font-weight: 500;
}

.oui-typography--decoration-none {
  text-decoration: none;
}

/* Colors */
.oui-text-orange-100 {
  color: #ffa500; /* Adjust to match your orange color */
}

.oui-text-aston-green-60 {
  color: #2d572c; /* Adjust to match your green color */
}

.oui-text-grey-100 {
  color: #333; /* Dark grey for better readability */
}

.oui-text-grey-05 {
  color: #fff; /* Lighter grey for cities and other less prominent text */
}

/* Responsive Design */
@media (max-width: 768px) {
  .neo-grid {
    grid-template-columns: 1fr;
  }

  .cities {
    flex-direction: column;
    gap: 10px;
  }

  .trust-markers {
    flex-direction: row;
    gap: 15px;
  }

  .download-app-buttons {
    flex-direction: column;
    gap: 10px;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .neo-info,
  .availability,
  .brand-info {
    padding: 15px;
  }

  .neo-header {
    flex-direction: column;
    gap: 5px;
  }

  .neo-grid-item {
    padding: 15px;
  }

  .cities,
  .trust-markers,
  .download-app-buttons {
    gap: 5px;
  }

  .cities {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }


  .city,
  .trust-markers > div {
    margin-bottom: 10px;
  }
  .trust-markers {
    flex-direction: row;
  }
}

/* Remove Box Shadow */
.neo-info,
.availability,
.brand-info,
.neo-grid-item {
  box-shadow: none;
}

.icon-white {
  filter: invert(100%) brightness(200%);
}

.obdDescE {
  padding: 0 !important;
}
