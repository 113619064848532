.about-wisedrive-header {
  display: flex;
}

.about-wisedrive-flex {
  display: flex;
  justify-content: space-around;
  margin-top: 4rem;
}
.about-wisedrive-inFlex {
  margin-top: 1rem;
  display: flex;
  gap: 50px;
}

.about-wisedrive-inFlex-content h2 {
  margin-bottom: 0px;
  font-size: 40px;
}
.about-wisedrive-inFlex-content p {
  padding-top: 0px;
}
