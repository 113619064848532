@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Manrope:wght@200..800&display=swap');

@font-face {
  font-family: 'matter';
  /*Can be any text*/
  /* src: local("matter"), url("../fonts/matter-Regular.ttf") format("truetype"); */
  src: local('matter'), url('../fonts/Matter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'matterBold';
  /*Can be any text*/
  src: local('matterBold'), url('../fonts/Matter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'matterMedium';
  /*Can be any text*/
  src: local('matterMedium'),
    url('../fonts/Matter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'matterThin';
  src: local('matterThin'), url('../fonts/Matter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'matterSemiBold';
  /*Can be any text*/
  src: local('matterSemiBold'),
    url('../fonts/Matter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'manrope';
  /*Can be any text*/
  /* src: local("matter"), url("../fonts/matter-Regular.ttf") format("truetype"); */
  src: local('manrope'),
    url('../fonts/manrope/manrope-regular.otf') format('truetype');
}

@font-face {
  font-family: 'manropeBold';
  /*Can be any text*/
  src: local('manropeBold'),
    url('../fonts/manrope/manrope-bold.otf') format('truetype');
}

@font-face {
  font-family: 'manropeMedium';
  /*Can be any text*/
  src: local('manropeMedium'),
    url('../fonts/manrope/manrope-medium.otf') format('truetype');
}

@font-face {
  font-family: 'manropeThin';
  src: local('manropeThin'),
    url('../fonts/manrope/manrope-thin.otf') format('truetype');
}

@font-face {
  font-family: 'manropeSemiBold';
  /*Can be any text*/
  src: local('manropeSemiBold'),
    url('../fonts/manrope/manrope-semibold.otf') format('truetype');
}

* {
  margin: 0;
  padding: 0px;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}

.headerBold {
  font-family: 'Manrope', sans-serif;
}

body {
  font-family: 'matter', sans-serif;
  line-height: 1.5;
  /* position: relative; */
  /* overflow-x: hidden; */
}

html {
  scroll-behavior: smooth;
}

section {
  width: 100%;
  padding: 50px 0;
}

img {
  max-width: 100%;
}

.commonHeading {
  margin-bottom: 60px;
}

/* .commonHeading h2 {
  font-weight: 400;
  font-size: 48px;
  line-height: 150%;
  color: #262c6e;
} */

p {
  font-family: 'matterThin';
  color: #0c286a;
  font-size: 20px;
  padding-top: 1rem;
}
.p-padding-top {
  padding-top: 0px;
}
.commonHeading h2 span {
  color: #eb6d47;
  font-weight: 600;
}

.cta-button {
  background: linear-gradient(107.01deg, #2836d7 0%, #8389d7 100%);
  border-radius: 8px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.cta-button:hover img {
  transform: translateX(5px);
}

.cta-button img {
  transition: all 0.3s ease-in-out;
}

.card {
  padding: 23px;
  border-radius: 15px;
  border: 1px solid transparent;
  width: 300px;
  max-width: 90%;
}

.card img {
  margin-bottom: 10px;
  display: inline;
}

.contactUsBtn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2836d7;
  padding: 14px 20px;
  display: flex;
  gap: 4px;
  border: 1px solid #2836d7;
  border-radius: 8px;
  width: fit-content;
}

.contactUsBtnModify {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 40px;
  padding-right: 40px;
  color: #2836d7;
  gap: 4px;
  border: 1px solid #2836d7;
  border-radius: 8px;
  width: fit-content;
}

.contactUsBtnModifyLine2 {
  font-size: 10px;
  margin-top: 0px;
  line-height: 12px;
}

.contactUsBtnModifyLine1 {
  margin-bottom: 0px;
  line-height: 12px;
  font-size: 20px;
}

.contactUsBtnGrediant {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background: linear-gradient(107.01deg, #2836d7 0%, #8389d7 100%);
  padding: 14px 20px;
  display: flex;
  gap: 4px;
  /* border: 1px solid #2836d7; */
  border-radius: 8px;
  width: fit-content;
}

.contactUsBtnModifyGrediant {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 40px;
  padding-right: 40px;
  color: #fff;
  background: linear-gradient(107.01deg, #2836d7 0%, #8389d7 100%);
  gap: 4px;
  border-radius: 8px;
  width: fit-content;
}

.contactUsBtn img {
  transition: all 0.5s ease-in-out;
}

select {
  cursor: pointer;
}

.contactUsBtn:hover img {
  transform: translateX(5px);
}

.contactUsBtnGrediant img {
  transition: all 0.5s ease-in-out;
}

.contactUsBtnGrediant:hover img {
  transform: translateX(5px);
}

/* Privacy, terms & conditions */
.commonConditions {
  padding: 152px 0 60px;
  background: linear-gradient(
    285.57deg,
    rgba(121, 130, 230, 0.1) 0%,
    rgba(186, 189, 217, 0.1) 100%
  );
}

.smallContainer {
  max-width: 1000px;
  margin: auto;
}

.commonConditions h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  color: #262c6e;
}

.commonConditions h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #262c6e;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.commonConditions p {
  font-family: 'matterMedium';
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #262c6e;
}

.commonConditions .smallContainer {
  display: flex;
  flex-direction: column;
  /* gap: 40px; */
}

.saveTimeAccordionQuestion {
  cursor: pointer;
}

.newCommonHeading {
  padding-bottom: 40px;
}

.newCommonHeading h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 133%;
  color: #212445;
}

#modal-root {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100vw;
  height: 100%; */
  /* z-index: -10; */
}

.modal-overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(0px);
}

.active,
.dot:hover {
  background-color: initial;
}

.orangeBtn {
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: #ffffff;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 24px 32px;
  background: #eb6d47;
  border: 1px solid #f05a2d;
  border-radius: 8px;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

.phaseTwo {
  padding: 60px 0;
}

h1 {
  line-height: 120%;
  font-size: 64px;
  font-weight: 700;
  color: #0c286a;
}

h2 {
  font-family: 'matterMedium';
  font-weight: 500 !important;
  line-height: 120% !important;
  font-size: 44px;
  margin-bottom: 0.5rem;
  color: #0c286a;
}

h3 {
  line-height: 120%;
  font-size: 48px;
  font-weight: 500;
  --text-opacity: 1;
  color: #314259;
  color: rgba(49, 66, 89, var(--text-opacity));
}

h4 {
  font-family: 'matterBold';
  line-height: 120%;
  font-size: 40px;
  font-weight: 500;
  --text-opacity: 1;
  color: #314259;
  color: rgba(49, 66, 89, var(--text-opacity));
}

h5 {
  line-height: 130%;
  font-size: 32px;
  font-weight: 500;
  --text-opacity: 1;
  color: #314259;
  color: rgba(49, 66, 89, var(--text-opacity));
}

h6 {
  font-family: 'matter';
  line-height: 140%;
  font-size: 22px;
  font-weight: 400;
  --text-opacity: 1;
  color: #314259;
  color: rgba(49, 66, 89, var(--text-opacity));
}

.container {
  width: 100%;
  margin: auto;
}

.btn-link {
  font-family: 'matterBold';
  padding-top: 8px;
  display: inline-flex;
  --text-opacity: 1;
  color: #1678fb;
  color: rgba(22, 120, 251, var(--text-opacity));
  font-size: 16px;
}

.btn-green-active {
  margin-left: -10px;
  font-weight: 700;
  padding: 0px 0px 0px 0px;
  --tw-bg-opacity: 1 !important;
  background-color: white !important;
  --tw-border-opacity: 1 !important;
  border-color: white !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 6px !important;
  height: 40px !important;
  width: 100% !important;
  font-size: 16px !important;
  color: #0a65c2;
}

.btn-green {
  margin-left: -10px;
  font-weight: 700;
  padding: 0px 0px 0px 0px;
  --tw-bg-opacity: 1 !important;
  background-color: #0a65c2 !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(204 230 225 / var(--tw-border-opacity)) !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 6px !important;
  height: 40px !important;
  width: 100% !important;
  font-size: 16px !important;
  color: white !important;
  align-content: flex-end;
  justify-content: center;
  align-items: center;
}

.taxCss {
  color: rgba(54, 54, 54, 0.6);
  font-size: 10px !important;
}

.cardPriceCss {
  margin-left: 5px;
  font-family: 'matterSemiBold';
  font-size: 18px !important;
}

/* .corosulHeader {
  padding-top: 10px;
  margin-left: 20px;
  color: white !important;
} */

.corosulHeaderRight {
  margin-right: 20px;
  padding: 10px 0px 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: self-end;
}

/* .corosulHeaderRight h6 {
  color: white;
} */

.headerContactUs {
  font-size: 16px !important;
  color: white !important;
  text-decoration: none !important;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
  .corosulHeaderRight {
    padding: 10px 10px 0px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
  .corosulHeaderRight {
    padding: 10px 10px 0px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (max-width: 992px) {
  .card {
    border: 1px solid rgba(40, 54, 215, 0.5);
  }
}

@media (max-width: 769px) {
  .mobile {
    display: block;
  }
  .corosulHeaderRight {
    padding: 10px 10px 0px;
  }
}

@media (max-width: 768px) {
  .phaseTwo {
    padding: 40px 0;
  }

  .desktop {
    display: none !important;
  }

  section {
    padding: 25px 0;
  }
  h2 {
    font-size: 32px;
  }
  p {
    font-family: 'matterThin';
    font-size: 18px;
  }
  .commonHeading {
    margin-bottom: 32px;
  }

  .commonHeading h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .commonConditions h1 {
    font-size: 30px;
    line-height: 38px;
  }

  .commonConditions {
    padding: 80px 0 40px;
  }

  .commonConditions p {
    font-size: 16px;
    line-height: 25px;
  }

  .commonConditions h2 {
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 8px;
  }

  .commonConditions .smallContainer {
    /* gap: 24px; */
  }
}

@media only screen and (orientation: portrait) {
  body {
    /* height: 100vw; */
    /* min-height: 100vw; */
    /* min-width: 100vh; */
    /* transform: rotate(90deg); */
  }
}

/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
