.location-wrap {
  padding-top: 45px !important;
  background-color: #0c286a;
}
.location-container {
  display: grid;
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
}

.location-container p {
  font-family: 'matter';
  color: white;
  font-size: 14px;
  padding-top: 0px;
}
.location-container h6 {
  color: white;
}

@media (max-width: 768px) {
  .location-container {
    display: grid;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
  }
}
@media (max-width: 480px) {
  .location-container {
    display: grid;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    padding-left: 15px;
  }
}
@media (max-width: 875px) {
  .location-container {
    display: grid;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    padding-left: 15px;
  }
}
@media (max-width: 776px) {
  .location-container {
    display: grid;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    padding-left: 15px;
  }
}
