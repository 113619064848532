/* brand Section */
.compare-Wrap {
  background-color: white;
}
.compare-wrap-header {
  /* font-size: 50px; */
  /* margin-bottom: 0px; */
}
.compare-wrap-desc {
  margin-bottom: 5px;
  padding-top: 0px;
}
.compare-Wrap .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.compare-wrap-table {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 1.5rem;
}
.compare-span-css {
  display: flex;
  flex-wrap: nowrap;
  font-family: 'matterMedium';
  flex-direction: row;
  line-height: 25px;
  margin-bottom: 1rem;
  gap: 6px;
}
/* left section */
.compare-left-section {
  border: 1px solid #f1f8f2;
  background-color: #f1f8f2;
  width: 100%;
  border-radius: 10px;
  padding: 15px;
}

.compare-left-section h6 {
  font-family: 'matterMedium';
  color: #25632d;
  margin-left: 30px;
  margin-bottom: 1rem;
}

.compare-left-section p {
  font-family: 'matter';
  color: #25632d;
  padding-top: 0px;
}
.compare-left-section p .highlet {
  font-family: 'matterSemiBold';
  color: #25632d;
}

.left-btn-container-compare {
  column-gap: 10px;
  margin-top: 75px;
  margin-bottom: 20px;
  min-width: 180px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.compare-button-green {
  /* border: 1px solid #43b97f !important; */
  height: 55px;
  font-size: 16px !important;
  font-weight: bold !important;
  padding: 0 24px;
  color: #fff !important;
  background-color: #43b97f !important;
  border-radius: 5px !important;
  overflow: hidden;
  text-transform: none !important;
}

.compare-button-white {
  color: #fff !important;
  border: 1px solid #43b97f !important;
  padding: 0 24px !important;
  border-radius: 6px !important;
  display: inline-flex;
  height: 60px !important;
  text-transform: none !important;
}

.compare-right-section {
  width: 100%;
  padding: 15px;
}
.compare-right-section h6 {
  color: #6571b7;
  font-family: 'matterMedium';
  margin-left: 30px;
  margin-bottom: 1rem;
}

.compare-right-section p {
  font-family: 'matter';
  color: #6571b7;
  padding-top: 0px;
}
.compare-right-section p .highlet {
  font-family: 'matterSemiBold';
}

.doneIconCompare {
  color: #25632d;
  padding-top: 0px;
  padding-bottom: 5px;
  vertical-align: text-bottom;
  width: 30px !important;
  height: 30px !important;
}
.removeIconCompare {
  color: #6571b7;
  padding-top: 0px;
  padding-bottom: 5px;
  vertical-align: text-bottom;
  width: 30px !important;
  height: 30px !important;
}

@media (max-width: 1330px) {
}

@media (max-width: 992px) {
  .compare-Wrap {
    padding: 10px;
  }
  .compare-wrap-header {
    font-size: 32px;
    padding-left: 5px;
    line-height: 40px !important;
  }
  .compare-wrap-desc {
    margin-bottom: 5px;
    padding-top: 1rem;
    line-height: 25px;
    padding-left: 5px !important;
  }
  .doneIconCompare {
    display: none;
  }
  .compare-Wrap .container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
  }

  .compare-wrap-table {
    flex-direction: column;
  }

  .compare-left-section {
    width: 100%;
    padding: 20px;
  }

  .compare-right-section {
    width: 100%;
    padding: 20px;
  }

  .compare-left-section h2 {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .compare-Wrap .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .compare-wrap-header {
    font-size: 32px;
    padding-left: 5px;
    line-height: 40px !important;
  }
  .compare-wrap-desc {
    margin-bottom: 5px;
    padding-top: 1rem;
    line-height: 25px;
    font-size: 18px;
    padding-left: 5px !important;
  }
  .doneIconCompare {
    display: none;
  }
  .compare-left-section {
    width: 100%;
    padding: 20px;
  }

  .compare-left-section h2 {
    font-size: 36px;
  }

  .compare-right-section {
    width: 100%;
    padding: 20px;
  }
  .compare-left-section p {
    font-size: 18px;
    font-family: 'matterThin';
    padding-left: 0px !important;
  }
  .compare-right-section p {
    font-size: 18px;
    font-family: 'matterThin';
    padding-left: 0px !important;
  }
}
