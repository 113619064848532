@font-face {
  font-family: 'matterSemiBold';
  /*Can be any text*/
  src: local('matterSemiBold'),
    url('../../fonts/Matter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'matterMedium';
  /*Can be any text*/
  src: local('matterMedium'),
    url('../../fonts/Matter-Medium.ttf') format('truetype');
}
.inspection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-family: 'matterThin'; */
}
.inspection-header-red {
  color: #ff7858;
  margin-bottom: 20px;
}

.inspection-plan-container {
  /* display: flex; */
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.inspection-plan-container-left {
  padding: 5px;
  /* width: 35%; */
}
.inspection-plan-container-left h2 {
  font-size: 40px;
}

.inspection-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3%;
  /* row-gap: 15px; */
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: end;
  justify-items: stretch;
}
.inspection-plan-btn-container {
  /* display: flex;
  column-gap: 3%;
  row-gap: 15px;
  align-content: center;
  width: 90%;
  margin-top: 10px; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3%;
  /* row-gap: 15px; */
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: end;
  justify-items: stretch;
}
/* .inspection-plan-btn-container {
  display: none;
} */
.inspection-plan-details-wrap {
  display: flex;
  gap: 20px;
  flex-direction: row;
  margin-top: 20px;
}
.inspection-card {
  background-color: white;
  border-radius: 5px !important;
  padding: 1rem;
  width: 30%;
  border: 1px solid #25632d;
}
.inspection-card-header {
  width: 100%;
  line-height: 30px;
  position: relative;
  font-size: 22px;
  font-family: 'matterMedium';
  color: #003cc5;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.inspection-card-header-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inspection-btn-container .inspection-plan-card {
  border: 1px solid grey;
  border-radius: 10px;
  /* padding: 20px; */
  max-width: 100%;
  height: fit-content;
}
.inspection-plan-btn-container .inspection-plan-card-active {
  border: 3px solid #003cc5;
  border-radius: 6px;
  padding: 20px;
  max-width: 100%;
}

.p-active {
  color: #003cc5 !important;
}

.inspection-plan-card .inspection-plan-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-top: 10px;
}
.inspection-plan-card-active .inspection-plan-card-header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.inspection-plan-card-active .inspection-plan-card-header p {
  font-size: 22px;
  font-family: 'matterMedium';
  color: #003cc5;
  padding-top: 0px;
}

.inspection-plan-card .inspection-plan-card-header p {
  font-size: 22px;
  font-family: 'matterMedium';
  padding-top: 0px;
  color: grey;
}

.inspection-plan-card p {
  /* font-size: 22px; */
  font-family: 'matter';
  color: grey;
}
.inspection-plan-card-active p {
  font-family: 'matterBold';
  color: #003cc5;
}

.inspection-plan-card-container {
  /* width: 60%;
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  padding: 10px; */
  display: flex;
  gap: 20px;
  flex-direction: row;
  margin-top: 20px;
}

.inspection-plan-stepper-container {
  width: 100%;
  padding: 10px;
  background-color: white;
  margin-top: 15px;
}
.inspection-plan-stepper-body {
  background-color: white;
  display: flex;
  justify-content: center;
}
.inspection-plan-stepper-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.inspection-plan-card {
  padding: 10px;
  border: #003cc5 1px solid;
  border-radius: 5px;
}

.inspection-plan-card-check-points {
  display: flex;
  height: 40px;
  width: 40px;
  background-color: antiquewhite;
  align-items: center;
  justify-content: center;
}

.inspection-card-list-items p {
  padding-top: 10px;
}

.inspection-card-list-items p span {
  font-family: 'matter' !important;
  font-size: 16px;
  color: #25632d;
}

.inspection-plan-card-list-items p span {
  font-family: 'matter' !important;
  font-size: 18px;
}

.additional-details-item {
  color: #003cc5 !important;
  font-family: 'matterMedium' !important;
  padding-top: 0px !important;
}
.additional-details-item span {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-family: 'matter';
  flex-direction: row;
  gap: 5px;
  color: #003cc5;
}

.inspection-card-additional-items {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.inspection-plan-card-content {
  margin: 2px;
}
.inspection-plan-card-header {
  display: flex;
}
.inspection-plan-card-header-container {
  display: flex;
  gap: 10px;
}
.inspection-plan-card-header-left {
  width: 100%;
}
.inspection-plan-card-header-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* align-content: center; */
  align-items: flex-end;
}
/* planDetails Section */
.planDetails {
  padding-top: 50px;
  max-width: 100%;
  margin: auto;
  /* padding-top: 25px; */
  --bg-opacity: 1;
  /* background-color: rgba(230, 235, 238, 255); default*/
  /* background-color: rgba(243, 246, 251, var(--bg-opacity)); last removed*/
  /* background-color: #f1f8ff; */
  /* margin-top: 90px; */
}
.inspection-plan-card-1 {
  background-color: #dbedff;
  /* border: 1px solid grey; */
  border-radius: 10px;
  padding: 10px;
  max-width: 100%;
}
.inspection-plan-card-1 .inspection-plan-card-check-points {
  background-color: #465886;
  color: #fdfdfd;
}
.inspection-plan-card-1 p {
  color: #465886 !important;
}
.inspection-plan-card-1 .inspection-plan-card-check-points h5 {
  color: #fdfdfd;
}
.circle-tick-1 {
  color: #2097bd; /* First condition */
}

.inspection-plan-card-2 {
  background-color: #ccedd0;
  /* border: 1px solid grey; */
  border-radius: 10px;
  padding: 10px;
  max-width: 100%;
}
.inspection-plan-card-2 .inspection-plan-card-check-points {
  background-color: #38b44a;
  color: #fdfdfd;
}
.inspection-plan-card-2 p {
  color: #465886 !important;
}
.inspection-plan-card-2 .inspection-plan-card-check-points h5 {
  color: #fdfdfd;
}
.circle-tick-2 {
  /* color: #38b44a; Second condition */
  color: #003cc5;
}
.inspection-plan-card-3 {
  background-color: #fff2e4;
  /* border: 1px solid grey; */
  border-radius: 10px;
  padding: 10px;
  max-width: 100%;
}
.inspection-plan-card-3 .inspection-plan-card-check-points {
  background-color: #ff7858;
  color: #fdfdfd;
}
.inspection-plan-card-3 p {
  color: #465886 !important;
}
.inspection-plan-card-3 .inspection-plan-card-check-points h5 {
  color: #fdfdfd;
}
.circle-tick-3 {
  color: #ff7858; /* Third condition */
}

.inspection-plan-card-4 {
  background-color: #ffe8fa;
  /* border: 1px solid grey; */
  border-radius: 10px;
  padding: 10px;
  max-width: 100%;
}
.inspection-plan-card-4 .inspection-plan-card-check-points {
  background-color: #e61f93;
  color: #fdfdfd;
}
.inspection-plan-card-4 p {
  color: #465886 !important;
}
.inspection-plan-card-4 .inspection-plan-card-check-points h5 {
  color: #fdfdfd;
}
.circle-tick-4 {
  color: #e61f93; /* Fourth condition */
}

.inspection-plan-card-header-left p {
  padding-top: 0px;
  padding-left: 8px;
  line-height: 28px;
  font-size: 22px;
}
.inspection-plan-card-check-points h5 {
  font-size: 22px;
}
.planDetails .planDetailsDetails {
  display: flex;
  justify-content: center;
}

.planDetailsDetails .planDetailsLogoSection {
  width: 40%;
  display: block;
}

.planDetails .commonHeading {
  max-width: 700px;
  margin-bottom: 40px;
}

.planDetails .commonHeading h2 {
  line-height: 120%;
  font-size: 56px;
  font-weight: 700;
  margin-bottom: 10px;
  --text-opacity: 1;
  color: #314259;
  color: rgba(49, 66, 89, 1);
}

.planDetailsCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 30px;
  row-gap: 20px;
}

.planDetailsCardsCorosul {
  border-radius: 40px;
  margin: auto;
  margin-top: 40px;
  background: none;
}
.headerComparePlans-link {
  font-size: 16px !important;
  /* color: white !important; */
  text-decoration: none !important;
}
.planDetailsCardsCorosul .corosulHeader {
  padding-top: 10px;
  /* margin-left: 20px; */
  width: 70%;
  color: #0c286a;
}
.planDetailsCardsCorosul .plan-container {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 25px; */
  /* padding: "40px 20px 40px 0px"; */
  /* align-items: "flex-end"; */
}
.planDetailsCardsCorosul .selectedPlan {
  background-color: #0a65c2 !important;
  box-shadow: #0a65c2 0px 4px 3px 1px !important;
}

.planDetailsCardsCorosul .card-container {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  padding-top: 20px;
}

.planDetailsCardsCorosul .card-container-two {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
  margin-top: 20px;
}

.planDetailsCardsCorosul .card {
  position: relative;
  display: flex;
  padding: 1.5rem;
  border: 1px solid #0c286a;
  gap: 0.5rem;
  background-color: white;
  flex-direction: column;
  width: 40%;
}

.header-section-split {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.planDetailsCardsCorosul .card1 {
  position: relative;
  display: flex;
  border-radius: 15px;
  border: 1px solid #0c286a;
  max-width: 90%;
  padding: 1.5rem;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  gap: 0.5rem;
  background-color: white;
  flex-direction: column;
  width: 100%;
}
.planDetailsCardsCorosul .headerCss {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
  color: #0c286a;
  font-family: 'matterSemiBold';
}
.planDetailsCardsCorosul .card p {
  color: #0c286a !important;
  padding-top: 0.1rem;
  font-size: 18px;
}
.planDetailsCardsCorosul .card1 p {
  color: #0c286a;
  padding-top: 0.1rem;
  font-family: 'matterMedium';
  font-size: 18px;
}

/* new css */
.corosul-card-new {
  display: flex;
  /* height: 100%; */
  padding: 1.5rem;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  gap: 2rem;
  background-color: #f6fafb;
  flex-direction: column;
}
.planDetailsCardsCorosul .corosulCard-header {
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
}
.corosulcard-title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-content: flex-start;
}

.corosulcard-price {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  /* margin-bottom: 2rem; */
}

.corosulcard-price h5 {
  color: #0c286a;
  font-family: 'matterSemiBold';
}

.plan-taxCss {
  color: #0c286a;
  font-size: 15px !important;
  font-family: 'matterThin';
}
.planDetailsCards .selectedPlan {
  background-color: #c6dfdb;
}

.planDetailsCardsCorosul .footerBtn {
  width: 5%;
  position: fixed;
  bottom: 30px;
  margin: 20px;
}

.post-purchase-body p {
  color: #25632d !important;
}
.doneIcon {
  margin-left: 4px;
  color: #0c286a;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-right: 5px;
  vertical-align: text-bottom;
  width: 20px;
  height: 20px;
}
.newIcon {
  color: #fddd48;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-right: 5px;
  vertical-align: text-bottom;
}

.doneIconSelected {
  color: #0c286a;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-right: 5px;
  vertical-align: text-bottom;
  width: 20px;
  height: 20px;
}

.viewMoreLink {
  font-size: 16px !important;
  text-decoration-color: rgba(25, 118, 210, 0.4);
  text-underline-position: under !important;
  display: flex;
  justify-content: center;
  font-weight: 500;
  line-height: 30px;
  color: #262c6e;
  align-items: center;
  padding: 50px 0px 0px;
  gap: 6px;
}

.slick-dots {
  position: static !important;
  display: block;
  width: 92%;
  padding: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  list-style: none;
  text-align: center;
}

.planDescription {
  margin: auto;
  margin-top: 40px;
  padding-top: 30px;
  padding-left: 60px !important;
  padding-right: 60px !important;
  padding-bottom: 45px !important;
  border-radius: 20px !important;
  display: grid !important;
  background-color: #fff !important;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 2px;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  z-index: 0;
  width: 92%;
}

.viewFooterText {
  font-weight: 600;
  margin-left: 15% !important;
  font-size: 15px;
  padding-top: 1rem;
  margin-bottom: 10px;
  text-decoration: none !important;
  color: #314259;
}

.priceDetailsNote {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comparePlansLink {
  color: white !important;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
}

.plan-details-popup-table {
  display: flex;
}

.modal-headerCss {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalCardContainer {
  display: flex;
  justify-content: space-around;
}

.modalCard {
  position: relative;
  margin: 20px;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid transparent;
  /* width: ; */
  box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4);
}

.tableCss p {
  font-family: 'matterMedium';
  font-size: 16px;
  color: black;
  padding: 0px;
  font-weight: 300;
}

.modalCard-active h6 {
  color: white !important;
}

.modalCard p {
  /* color: white !important; */
  padding-top: 0px;
  font-size: 16px;
}
.web-view-btn {
  display: flex;
  justify-content: center;
  margin-top: 7px;
}

.mobile-view-btn {
  display: none;
}

.modalCard-active p {
  color: #0a65c2 !important;
  padding-top: 0px;
  font-size: 16px;
}

.modalCard-active span {
  /* color: white !important; */
}

.tableCss {
  height: 400px;
}

.modalCard::after {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  background-color: white;
  padding: 0 10px;
}

.div-border {
  top: -10px;
  right: -10px;
  position: absolute;
}

.modal-responsive-css {
  width: 700px;
}

.modal-footerBtn {
  padding-top: 50px;
}

.sup-star {
  font-size: 24px; /* Adjust size as needed */
  /* vertical-align: top; */
  padding-left: 5px;
  vertical-align: super;
}

.plan-details-benifits-mobile {
  display: none;
}

.inspection-plan-btn-container-mobile {
  display: none;
}

.inspection-btn-container-mobile {
  display: none;
}
@media (max-width: 1330px) {
  .downloadScanImage {
    right: 0;
  }
}

@media (max-width: 992px) {
  /* .planDetails {
    background-color: #003cc5;
  } */
  .web-view-btn {
    display: none;
  }
  .mobile-view-btn {
    display: flex;
    justify-content: space-around;
    margin-top: 0px;
    align-items: center;
  }
  .planDetailsCards {
    flex-wrap: wrap;
  }
  .plan-details-benifits-mobile {
    display: block;
    margin-top: 1.5rem;
    padding-left: 10px;
  }
  .planDetailsCards .card {
    padding: 24px;
    width: 100%;
    border: none !important;
  }

  .planDetailsCardsCorosul .card {
    width: 100%;
  }

  .mobile-card-header {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    row-gap: 20px;
    padding-left: 20px;
    align-content: center;
  }

  .planDetailsCardsContainerMobile .card {
    margin: 20px;
    padding: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 2px; */
    box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4);
    background-color: white;
    height: 50vh;
    width: auto;
  }

  .card-container-two {
    display: none !important;
  }

  .planDetailsCardsCorosul .card p {
    font-size: 16px;
  }

  .viewMoreLink {
    font-size: 16px !important;
    text-decoration-color: rgba(25, 118, 210, 0.4);
    text-underline-position: under !important;
    display: flex;
    justify-content: center;
    font-weight: 500;
    line-height: 30px;
    color: #262c6e;
    padding: 0px;
    align-items: center;
    gap: 6px;
  }

  .modal-headerCss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-responsive-css {
    width: 300px;
    height: inherit;
    overflow: auto;
  }

  .mobileCardContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 10px;
  }

  .modalCardMobile {
    position: relative;
    margin: 15px;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid transparent;
    /* background-color: #EFF5FD; */
    box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4);
  }

  .modalCardMobile p {
    font-size: 12px;
    padding-top: 2px;
  }

  /* .modalCard-active-mobile {
        background-color: bisque;
    } */

  .modalCard-active-mobile {
    color: #0a65c2 !important;
  }

  .cardPrice-mobile {
    font-weight: 700;
    font-size: 12px !important;
  }

  .taxCss-mobile {
    padding-top: 0px;
    font-size: 10px;
    --text-opacity: 1;
    color: #9092a3 !important;
    color: rgba(144, 146, 163, var(--text-opacity)) !important;
  }

  .card {
    border: none !important;
  }

  .corosulHeaderRight {
    align-items: center;
  }

  .footerBtn-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .price-detail-mobile {
    padding-top: 0px;
    text-align: center;
    font-size: 12px;
  }

  .plandetails-footer-mobile {
    text-align: center;
  }

  .plandetails-mobile-contactus {
    --text-opacity: 1;
    font-weight: bolder;
    text-underline-position: under !important;
    color: rgba(49, 66, 89, var(--text-opacity));
    text-decoration-color: rgba(49, 66, 89, var(--text-opacity)) !important;
  }
  .planDetailsCardsCorosul .corosulHeader {
    width: 90%;
    font-family: 'matterSemiBold';
    font-size: 34px;
    line-height: 40px;
    padding-left: 5px;
  }
}

@media (max-width: 768px) {
  .inspection-header {
    font-size: 32px;
  }
  .inspection-header-red {
    font-size: 32px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .web-view-btn {
    display: none;
  }
  .mobile-view-btn {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
  }
  .planDetailsCardsCorosul .card {
    width: 100%;
  }
  .inspection-plan-stepper-container {
    width: 100%;
  }
  .planDetailsCardsCorosul .corosulHeader {
    width: 90%;
    font-family: 'matterSemiBold';
    font-size: 34px;
    line-height: 40px;
    padding-left: 10px;
  }
  .planDetailsCards .card h3 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 23px;
  }

  .inspection-plan-container {
    flex-direction: column;
  }
  .inspection-plan-container-left {
    width: 100%;
  }
  .inspection-plan-container-left h2 {
    /* font-size: 34px; */
    margin-bottom: 24px;
  }
  .inspection-plan-btn-container {
    display: none;
    width: 100%;
  }
  .inspection-btn-container {
    display: none;
    width: 100%;
  }
  .inspection-btn-container-mobile {
    display: flex;
    flex-direction: column;
    column-gap: 3%;
    row-gap: 15px;
    align-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .inspection-plan-btn-container-mobile {
    display: flex;
    flex-direction: column;
    column-gap: 3%;
    row-gap: 0px;
    align-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .inspection-plan-card-container {
    /* width: 100%;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    padding: 10px; */
    display: flex;
    flex-direction: column;
  }
  .inspection-card {
    width: 100%;
  }
  .planDetailsCards .card p {
    font-size: 16px;
    line-height: 24px;
  }

  .card-container-two {
    display: none !important;
  }

  .planDetailsCardsCorosul .card p {
    font-size: 14px;
  }
  .modal-headerCss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modalCardMobile {
    position: relative;
    margin: 15px;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid transparent;
    /* background-color: #EFF5FD;*/
    box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4);
  }
  .planDetailsCardsCorosul {
    margin-left: 10px;
  }
  .planDetailsCardsCorosul .plan-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2rem;
  }
  .planDetailsCardsCorosul .card-container {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    margin-top: 2rem;
  }

  .planDetailsCardsContainerMobile .card {
    margin: 20px;
    padding: 30px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 2px; */
    box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4);
    background-color: white;
    height: 60vh;
    width: 100%;
  }

  .cardPrice-mobile {
    font-weight: 700;
    font-size: 12px !important;
  }

  .taxCss-mobile {
    padding-top: 0px;
    font-size: 10px;
    --text-opacity: 1;
    color: #9092a3 !important;
    color: rgba(144, 146, 163, var(--text-opacity)) !important;
  }

  .mobile-card-header {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    row-gap: 20px;
    padding-left: 20px;
    align-content: center;
  }

  .viewMoreLink {
    font-size: 14px !important;
    text-decoration-color: rgba(25, 118, 210, 0.4);
    text-underline-position: under !important;
    display: flex;
    justify-content: center;
    font-weight: 500;
    line-height: 30px;
    color: #262c6e;
    padding: 0px;
    align-items: center;
    gap: 6px;
  }

  .modalCardMobile p {
    font-size: 12px;
  }

  .corosulHeaderRight {
    align-items: center;
  }

  .footerBtn-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .price-detail-mobile {
    padding-top: 0px;
    text-align: center;
    font-size: 12px;
  }

  .plandetails-footer-mobile {
    text-align: center;
  }

  .plandetails-mobile-contactus {
    --text-opacity: 1;
    font-weight: bolder;
    text-underline-position: under !important;
    color: rgba(49, 66, 89, var(--text-opacity));
    text-decoration-color: rgba(49, 66, 89, var(--text-opacity)) !important;
  }
  .plan-details-benifits-mobile {
    display: block;
    margin-top: 1.5rem;
    padding-left: 10px;
  }
}
