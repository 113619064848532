.status-container {
  width: 50%;
  display: flex;
  justify-content: center;
}

.payment-status-body {
  /* display: flex; */
  gap: 20px;
  padding-top: 20px;
  margin: 10px;
}

/* .payment-status-body .left-container {
  width: 60%;
}
.payment-status-body .right-container {
  width: 40%;
} */

.payment-status-success-container {
}
.payment-status-header {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.status-icon {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}
.transaction-details-container {
  width: 100%;
  grid-column-gap: 60px;
  grid-row-gap: 25px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  padding: 30px;
  justify-content: space-evenly;
  align-content: center;
  justify-items: stretch;
}

.payment-status-transaction-details {
}

.payment-status-transaction-details p {
  padding-top: 0px;
}

.payment-status-failure-container {
}

.company-ltd {
  font-family: 'matter';
  color: gray;
  padding-top: 0px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .status-icon {
    margin-top: 5px;
    flex-direction: column;
    gap: 10px;
  }
  .company-ltd {
    font-family: matter;
    color: gray;
    padding-top: 0px;
    font-size: 14px;
    text-align: center;
  }

  .transaction-details-container {
    grid-row-gap: 10px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    padding: 10px;
  }

  .book-inspection-contact {
    font-size: 18px;
  }

  .mobile-note-payment {
    padding: 10px !important;
  }

  .status-container {
    margin-top: 10px;
    width: 100%;
  }

  .whatsapp-mobile-payment {
    width: 100% !important;
  }
}
