/* @font-face {
  font-family: "matterBold";
  /*Can be any text*
  src: local("matterBold"),
    url("../../fonts/matter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "matterMedium";
  /*Can be any text*
  src: local("matterMedium"),
    url("../../fonts/matter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "matter";
  /*Can be any text*
  src: local("matter"), url("../../fonts/matter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "matterThin";
  /*Can be any text*
  src: local("matterThin"),
    url("../../fonts/matter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "matterSemiBold";
  /*Can be any text*
  src: local("matterSemiBold"),
    url("../../fonts/matter-SemiBold.ttf") format("truetype");
} */

.customers {
  background-color: #f8f8f8;
  /* background-color: #f0f0f0; */
}

.customers .commonHeading {
  width: 540px;
  max-width: 100%;
}

.customers .commonHeading h2 {
  color: #262c6e;
}

.customer-review-header {
  display: flex;
  justify-content: space-between;
}
/* .customersContent {
    display: flex;
    gap: 36px;
    position: relative;
  } */
/* .prev {
    position: absolute;
    right: 186px;
    top: -68px;
  }
  .next {
    position: absolute;
    right: 120px;
    top: -68px;
  } */
.customersCard {
  padding: 32px;
  background: #003cc4;
  border-radius: 18px;
  max-width: 560px;
  width: 100%;
  /* gap: 16px; */
  /* height: 360px; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

.customerCardParent {
  padding-right: 32px;
}

.customersCard p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: white;
  padding-bottom: 32px;
  min-height: 241px;
}

.customersCard img {
  display: block;
  padding-top: 8px;
  padding-bottom: 16px;
}

.customersCardProfile {
  display: flex;
  gap: 8px;
  align-items: center;
}

.customersCardProfile img {
  padding: 0;
  display: block;
  width: 56px;
}

.customer-card-profile h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0c286a;
  /* padding: 5px; */
}

.customer-card-profile label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ff8966;
}

.customers .slick-slider {
  /* width: 1440px; */
  max-width: 100%;
  margin: auto;
}

/* .customers .slick-slide {
  margin-right: 36px;
  width: 400px !important;
} */
.customers {
  overflow: hidden;
}

.customers .slick-list {
  padding-left: 120px;
  /* max-width: 1440px; */
  margin: auto;
  padding: 30px;
}
.customer-review-footer-p1 {
  display: flex;
  gap: 60px;
}
/* 
.customers .slick-list::before {
  content: "";
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  background: linear-gradient(to right, #F5F6FC 15.78%, rgba(245, 246, 252, 0) 68.28%);
  left: 0;
  z-index: 9;
}

.customers .slick-list::after {
  content: "";
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  background: linear-gradient(to left, #F5F6FC 15.78%, rgba(245, 246, 252, 0) 68.28%);
  right: 0;
} */

/* .customers .slick-track {
  margin-left: 178px !important;
  height: inherit;
} */
.customers .slick-prev:before,
.customers .slick-next:before {
  color: transparent !important;
}

.customers .slick-prev,
.customers .slick-next {
  width: 36px !important;
  height: 36px !important;
  top: -68px !important;
}

.customers .slick-prev {
  left: auto !important;
  right: 186px !important;
  background-image: url(../../assets/images/home/previous.svg) !important;
}

.customers .slick-next {
  right: 120px !important;
  background-image: url(../../assets/images/home/next.svg) !important;
}

/* .customers .slick-current p{
  color:
} */

/* @media (min-width:1450px) and (max-width:1550px){
  .customers .slick-list{
    padding-left: 480px;
  }
} */

/* Latest CSS */
.customer-card-wraper {
  padding-right: 40px;
}

.customer-card {
  padding: 30px;
  /* background: linear-gradient(96.82deg, #d2d5f7 0%, #eeeffc 100%); */
  border-radius: 18px;
  max-width: 260px;
  width: 100%;
  /* height: 450px; */
  background-color: #eff2ff;
  box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4);
}

.customer-card-profile {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  row-gap: 5px;
  /* padding-top: 30px; */
  padding-bottom: 20px;
}

.customer-card p {
  font-family: 'matterThin';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #0c286a;
  /* text-align: center; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical;
}

.customer-card-active {
  box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4);
}

.customer-card-active p {
  font-size: 20px;
  font-family: 'matterMedium';
  color: #0c286a;
}

.customers .customer-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
}

.customers .customer-heading h2 {
  margin-bottom: 0px !important;
  color: transparent;
  background: linear-gradient(
      225deg,
      rgb(250, 187, 0) 0%,
      rgb(208, 188, 141) 59.29%,
      rgb(154, 26, 137) 99.81%
    )
    text !important;
}

.customers .customer-heading p {
  font-size: 25px;
  color: white;
  text-align: center;
}

.customer-review-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2.5rem;
  gap: 8%;
}

.customer-review-footer h2 {
  margin-bottom: 0px;
  font-size: 42px;
  color: #003cc5;
}
.customer-review-footer p {
  padding-top: 0px;
  font-size: 20px;
  color: #003cc5;
  font-family: 'matter';
}

.reviews-on-Google {
  font-size: 20px !important;
}
.customer-review-header-right {
  display: flex;
  flex-direction: column;
  padding-right: 45px;
  align-items: center;
}
.img-rating {
  width: 150px;
}
@media (max-width: 768px) {
  /* .customers .slick-slide {
    margin-right: 8px;
    width: 343px !important;
  } */
  .customers .slick-list::before,
  .customers .slick-list::after {
    content: none;
  }
  .customers .slick-slider {
    height: 350px;
  }
  .customer-review-header {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .customer-review-header-right {
    padding-left: 20px;
  }
  .img-rating {
    width: 100px;
  }
  .customer-review-footer {
    flex-direction: column;
    gap: 10px;
  }

  .customer-card {
    width: 100%;
  }
  .customersCard p {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
    min-height: 208px;
  }

  .customer-card-profile h5 {
    font-size: 14px;
    line-height: 22px;
    /* padding: 5px; */
  }

  .customer-card-profile label {
    font-size: 12px;
    line-height: 16px;
  }
  .reviews-on-Google {
    font-size: 16px !important;
  }
  /* .customers .slick-track {
    margin-left: 39px !important;
  } */
  .customers {
    padding-bottom: 65px;
    padding-right: 5px;
  }
  .customers h2 {
    /* font-family: 'matterSemiBold'; */
    /* font-size: 34px; */
    line-height: 40px;
    padding-left: 20px;
  }
  .customers p {
    padding-left: 20px !important;
  }
  .customer-review-footer-p1 {
    gap: 10px;
    margin-bottom: 1rem;
  }
  .customer-review-footer .customers .slick-prev,
  .customers .slick-next {
    bottom: -64px !important;
    top: auto !important;
  }

  .customers .slick-next {
    right: 24px !important;
  }

  .customers .slick-prev {
    right: 86px !important;
  }

  .customers .slick-list {
    padding-left: 80px;
  }
}

@media (max-width: 630px) {
  .customers .slick-list {
    padding-left: 24px;
  }
  .customer-card {
    width: 100%;
  }
  .customers .slick-slider {
    height: 350px;
  }

  .customer-review-footer {
    flex-direction: column;
    gap: 10px;
  }
  .customer-review-footer-p1 {
    gap: 10px;
    margin-bottom: 1rem;
  }
  .reviews-on-Google {
    font-size: 18px !important;
  }
}

@media (max-width: 550px) {
  /* .customers .slick-list {
    padding-left: 120px;
  } */
  .customer-card {
    width: 100%;
  }
  .customersCard {
    max-width: 336px;
    padding: 24px;
  }

  .customersCard p {
    min-height: 304px;
  }

  .customerCardParent {
    padding-right: 20px;
  }
  .customers .slick-slider {
    height: 350px;
  }

  .customer-review-footer {
    flex-direction: column;
    gap: 10px;
  }
  .reviews-on-Google {
    font-size: 18px !important;
  }
}

/* @media (max-width: 480px) {
  .customers .slick-list {
    padding-left: 80px;
  }
} */
/* @media (max-width: 400px){
.customers .slick-list {
    padding-left: 54px;
}
} */
@media (max-width: 360px) {
  .customersCard {
    max-width: 280px;
  }

  .customers .slick-list {
    padding-left: 20px;
  }
  .reviews-on-Google {
    font-size: 18px !important;
  }
}
