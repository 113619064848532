@font-face {
  font-family: 'matter';
  /*Can be any text*/
  src: local('matter'), url('../../fonts/Matter-Regular.ttf') format('truetype');
}

.footer-container {
  padding: 0 0 7px;
}
.footer-container .footerLogo {
  margin-bottom: 24px;
  display: block;
}
.footer-container img {
  display: block !important;
}

.footer-container h4 {
  margin-bottom: 10px;
  color: #fff;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1.5px;
}

.footerLinks h6 {
  font-family: 'matterMedium';
  color: black;
  font-size: 1.5rem;
  /* margin-bottom: 1rem; */
  cursor: default;
}

.footer-container p {
  padding-top: 0px;
  margin-bottom: 40px;
  /* color: #9fdfff; */
  width: 70%;
  color: rgb(0, 60, 197);
  font-family: matter;
  font-size: 18px;
}

.footerDetails {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 60px !important;
  border-bottom: 3px solid rgba(108, 110, 137, 0.24);
  flex-wrap: wrap;
  column-gap: 130px !important;
  row-gap: 30px;
}

.footerPartner .slick-slide {
  margin-right: 32px;
}

.footerPartner .slick-track {
  display: flex !important;
  align-items: center;
}

.footerBrand {
  width: 300px;
  order: 0;
}

.footerBrand p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #464868;
  margin-bottom: 30px;
}

.footerSocialLinks {
  display: flex;
  gap: 18px;
  align-items: center;
}

.footerSocialLinks a svg path {
  transition: all 0.5s;
}

.footerSocialLinks a:hover svg path {
  fill: #33439c;
}

.footerLinks {
  max-width: 100%;
}

.footerLinks ul {
  display: flex;
  flex-direction: column;
  gap: 2px !important;
}

.footerLinks ul li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #262c6e;
}

.footerPartner {
  padding: 12px 13px 7px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(108, 110, 137, 0.24);
  flex-wrap: wrap;
  row-gap: 18px;
}

.footerAssistance {
  padding: 22px 13px 22px 20px;
}

.footerPartner p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #464868;
  flex-basis: 20%;
}

.footerPartner ul {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 2rem 0;
  margin: 0;
}

.footer-branding {
  border-top: 2px solid red !important;
  border-bottom: 2px solid #dbd1e0;
  /* margin-bottom: 6rem; */
}

.footer-branding p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #464868;
  flex-basis: 20%;
}

.footer-optin {
  background-color: white;
  display: flex;
  margin-top: 3rem;
}

.footer-optin h4 {
  color: black;
}

.footer-optin p {
  color: black;
}

.footer-optin-right {
  display: flex;
  /* align-content: center; */
  align-items: center;
  gap: 10px;
  width: 60%;
}

.footer-branding-ul {
  display: flex;
  justify-content: space-between;
}

.footer-branding-li {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 10px;
}

ul li p {
  margin-bottom: 0px !important;
  color: black !important;
  font-size: 12px !important;
  width: 100% !important;
}

ul li h6 {
  color: black;
  font-size: 20px;
  cursor: default;
}

.footerPartnerLogos {
  /* display: flex; */
  flex-basis: 80%;
  /* align-items: center; */
  /* gap: 32px; */
  /* overflow-x: scroll; */
  overflow: hidden;
}

.footerCopyRight {
  padding: 0px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start !important;
  margin-top: 1.5rem;
}
.footerDetails-container {
  display: grid;
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  /* grid-template-rows: 1fr 1fr; */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  margin-bottom: 1rem;
  /* 
  padding-bottom: 60px !important;
  border-bottom: 3px solid rgba(108, 110, 137, 0.24);
  flex-wrap: wrap;
  column-gap: 130px !important;
  row-gap: 30px; */
}
.footerCopyRight p {
  padding-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0c286a;
}

.footerCopyRight ul {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 14px;
}

.footerCopyRight ul li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9d9fbd;
}

.footerLinks ul li::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #eb6d47;
  transition: width 0.5s;
}
.footerLinks ul li {
  width: fit-content;
}
.footerLinks ul li:hover:after {
  width: 100%;
}

.newsletter-left {
  width: 100%;
}
.newsletter-right {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.newsletter-button-green {
  height: 50px;
  font-size: 16px !important;
  font-weight: bold !important;
  /* padding: 0 24px; */
  color: #fff !important;
  background-color: #43b97f !important;
  border-radius: 5px !important;
  overflow: hidden;
  text-transform: none !important;
  padding-left: 5px;
  margin-right: 8px;
}

.footer-header {
  padding-top: 50px;
  /* padding-bottom: 0px; */
  margin-bottom: 30px;
  background-color: #eff2ff;
}

.foter-header-body {
  display: flex;
  gap: 5%;
  align-items: center;
}

.btn_news {
  padding-right: 0px !important;
  background: white;
}

.footerCopyRightUpdate {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footerSocialLinksNew {
  display: flex;
  gap: 18px;
  align-items: center;
}

.footerCopyRight-web {
  display: block;
}

.footerCopyRight-mobile {
  display: none;
}
@media (min-width: 1250px) {
  footer .container {
    max-width: 1200px;
    padding: 0;
  }
  .footerCopyRight-web {
    display: block;
  }
  .footerCopyRight-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .footerCopyRight-web {
    display: none;
  }
  .footerCopyRight-mobile {
    display: block;
  }
  .footer-header {
    padding-bottom: 10px;
  }
  .footer-container p {
    width: 100%;
  }
  .footer-optin h4 {
    padding-left: 10px;
    font-family: 'matterSemiBold';
    font-size: 34px;
  }
  .footer-optin-right {
    padding-left: 10px;
  }
  .footerDetails-container {
    /* grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr; */
    /* justify-content: space-between; */
    padding-left: 15px;
    display: flex;
    margin-bottom: 0px;
    padding-bottom: 0px;
    /* border-bottom: 3px solid rgba(108, 110, 137, 0.24); */
    flex-wrap: wrap;
    column-gap: 130px !important;
    row-gap: 30px;
  }
  .footer-optin {
    background-color: white;
    padding: 5px;
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
  }
  .footer-optin-right {
    display: flex;
    /* align-content: center; */
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  /* .footerSocialLinks {
    display: flex;
    gap: 35%;
    justify-content: space-around;
  } */
  .foter-header-body {
    display: flex;
    flex-direction: column;
    gap: 2%;
  }
  .footerCopyRightUpdate {
    display: flex;
    flex-direction: column;
  }
  .footerSocialLinksNew {
    display: flex;
    gap: 0%;
    margin-left: 5%;
  }
  .footerPartner p,
  .footerPartnerLogos {
    flex-basis: 100%;
  }
  .footerPartner .slick-slide {
    margin-right: 16px !important;
  }
  .footerPartnerLogos img {
    max-width: 112px !important;
  }
  .footerPartner {
    padding-left: 0;
    padding-right: 0;
  }
  .footerLinks {
    max-width: 100%;
    width: 200px;
  }
  .footerLinks ul li a {
    font-family: inherit;
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .footerCopyRight-web {
    display: none;
  }
  .footerCopyRight-mobile {
    display: block;
  }
  .footer-header {
    padding-bottom: 10px;
  }
  .footer-container p {
    width: 100%;
  }
  .footer-optin {
    background-color: white;
    padding: 5px;
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
  }
  .footer-optin-right {
    display: flex;
    /* align-content: center; */
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  .footerSocialLinks {
    display: flex;
    gap: 35%;
    justify-content: space-around;
  }
  .foter-header-body {
    display: flex;
    flex-direction: column;
    gap: 2%;
  }
  .footerLinks:nth-child(2) {
    order: 1;
  }
}
@media (max-width: 875px) {
  .footer-header {
    padding-bottom: 10px;
  }
  .footer-container p {
    width: 100%;
  }
  .footer-optin {
    background-color: white;
    padding: 5px;
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
  }
  .footer-optin-right {
    display: flex;
    /* align-content: center; */
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  .footerDetails-container {
    padding-left: 15px;
    display: flex;
    /* border-bottom: 3px solid rgba(108, 110, 137, 0.24); */
    flex-wrap: wrap;
    column-gap: 130px !important;
    row-gap: 30px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .foter-header-body {
    display: flex;
    flex-direction: column;
    gap: 2%;
  }
  .footerSocialLinks {
    display: flex;
    gap: 35%;
    justify-content: space-around;
  }
  .footerCopyRight-web {
    display: none;
  }
  .footerCopyRight-mobile {
    display: block;
  }
}
@media (max-width: 776px) {
  .footer-header {
    padding-bottom: 10px;
  }
  .footer-container p {
    width: 100%;
    padding-left: 15px !important;
  }
  .footer-optin {
    background-color: white;
    padding: 5px;
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
  }
  .footer-optin-right {
    display: flex;
    /* align-content: center; */
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  .footerSocialLinks {
    display: flex;
    gap: 35%;
    justify-content: space-around;
  }
  .foter-header-body {
    display: flex;
    flex-direction: column;
    gap: 2%;
  }
  .footerBrand {
    width: 100%;
  }
  .footerCopyRight-web {
    display: none;
  }
  .footerCopyRight-mobile {
    display: block;
  }
}
