.noReport .reportContentContainer{
    padding: 40px;
    border-radius : 8px;
}
.noReport .reportContainer{
    padding: 0;
    align-items: flex-start;
}
.reportContentContainer .reportAccess{
    gap: 24px 12px;
}
.noReport .reportContentContainer .noReportPara{
    display: flex;
    gap: 8px;
    padding-bottom: 35px;
    align-items: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(38, 44, 110, 0.8);
}
.mb-20{
    margin-bottom: 20px;
}
.reportAccess span{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #F05A2D;
    display: block;
}
.noReport .reportAccess div:first-child{
    flex-basis: 63%;
}
.recommendation .recommendationContainer{
    padding: 40px;
    background: linear-gradient(285.57deg, rgba(121, 130, 230, 0.1) 0%, rgba(186, 189, 217, 0.1) 100%), #FFFFFF;
    border-radius: 16px;
}

.recommendationHeading{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 0.5px solid #2836D7;
}
.recommendationHeading h2{
    font-weight: 500;
    font-size: 24px;
    line-height: 133%;
    color: #212445;
}
.recommendationHeading p{
    font-weight: 500;
    font-size: 24px;
    line-height: 133%;
    color: #212445;
    display: flex;
    align-items: center;
    gap: 8px;
}
.recommendationContent{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px 20px;
}
.recommendationCard{
    padding: 12px 15px;
    max-width: 360px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 0.5px solid #2837d750;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.recommendationCard h3{
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: #262C6E;
}
.recommendationCard img{
height: 190px;
width: 330px;
border-radius: 16px;

}
.location-icon{
height: 26px !important;
width: 20px !important;

border-radius: 0px;

}
.recommendationCard h3 span{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #7C7FA7;
    padding-left: 8px;
}
.recommendationCard label{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(124, 127, 167, 0.8);
    display: block;
    padding-top: 8px;
}
.carSpecs{
    display: flex;
    justify-content: space-between;
}
.carSpecs h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 188%;
    color: #7C7FA7;    
}
.carSpecs p{
    font-weight: 400;
    font-size: 16px;
    line-height: 188%;
    color: rgba(124, 127, 167, 0.7);    
}
.recommendationCard a{
    font-weight: 400;
    font-size: 14px;
    line-height: 171%;
    color: rgba(124, 127, 167, 0.7);
    display: flex;
    gap: 16px;
    align-items: center;
    padding-top: 8px;
    border-top: 0.5px solid rgba(124, 127, 167, 0.8);
}
.loadMore{
    padding-top: 40px;
}
.loadMore a{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 6px 16px 6px 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: rgba(38, 44, 110, 0.8);
    background: rgba(121, 130, 230, 0.2);
    border-radius: 24px;
    width: fit-content;
    margin: auto;
}
.loadMore a img:first-child{
    transform: scaleX(-1);
}

@media (max-width:768px){
    .recommendationHeading{
        border-bottom: none;
        justify-content: center;
        gap: 16px;
    }
    .recommendationHeading h2{
        border-bottom: 1px solid #2837d750;
        padding-bottom: 16px;
    }
    .recommendationHeading p{
        font-size: 14px;
        gap: 4px;
        padding: 6px 4px;
        border: 1px solid #2837d750;
        border-radius: 8px;
    }
    .recommendationHeading p img{
        width: 16px;
        height: 16px;
    }
    .noReport .reportAccess{
        padding: 16px;
    }
    .recommendationCard{
        max-width: 100%;
    }
    .noReport .reportAccess div:first-child{
        flex-basis: 100%;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
    .noReport .reportContentContainer {
        padding: 22px 16px;
    }
    .recommendationCard h3{
        font-size: 20px;
    }
    .recommendationContent{
        gap: 24px;
    }
    .recommendationCard h3 span{
        font-size: 14px;
    }
    .recommendationCard label{
        font-size: 14px;
        padding-top: 4px;
    }
    .carSpecs h4{
        font-size: 14px;
    }
    .carSpecs p{
        font-size: 14px;
    }
    .recommendationCard{
        padding: 16px;
    }
    .recommendation .recommendationContainer{
        padding: 24px;
        border-radius: 8px;
    }
    .loadMore{
        padding-top: 24px;
    }
    
}