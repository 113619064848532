.plan-details-body {
  display: flex;
  gap: 20px;
  padding-top: 20px;
  margin: 10px;
}

.plan-details-body .left-container {
  width: 60%;
}
.plan-details-body .right-container {
  width: 40%;
}

.plan-details-buynow-cards-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3%;
  row-gap: 24px;
  align-content: center;
}

.plan-details-buynow-cards-container .buy-now-card {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 20px;
  max-width: 100%;
  /* height: 130px; */
}
.plan-details-buynow-cards-container .buy-now-card-active {
  border: 3px solid #003cc5;
  border-radius: 6px;
  padding: 20px;
  max-width: 100%;
}

.buy-now-card .buy-now-card-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
}
.buy-now-card-active .buy-now-card-header {
  display: flex;
  justify-content: flex-start;
  /* gap: 5px; */
  align-items: center;
}

.buy-now-card-active .buy-now-card-header h5 {
  font-size: 22px;
  font-family: 'matterBold';
  color: #003cc5;
  padding-top: 15px;
}

.buy-now-card .buy-now-card-header h5 {
  font-family: 'matter';
  font-size: 22px;
  color: grey;
}

.buy-now-card p {
  font-family: 'matter';
  color: grey;
}
.buy-now-card-active p {
  font-family: 'matterBold';
  color: #003cc5;
}
.inspection-list-header {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 5px;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
}
.inspection-list-header h5 {
  font-size: 20px;
}
.plan-details-buyNow-btn-container {
  display: flex;
  justify-content: flex-start;
  gap: 5%;
  margin-bottom: 2rem;
}

/* .componentWrapper {
  border: solid cadetblue;
  border-radius: 40px;
  padding: 15px 10px 10px;
  width: 95%;
} */

.plan-details-buynow-cards-container .premium {
  font-family: 'matterSemiBold';
  position: relative;
  margin-top: -35px;
  margin-left: 10px;
  color: #003cc5;
  background: orange;
  border-radius: 10px;
  padding: 2px 10px;
}

.plan-details-buynow-cards-container .plan-details-btn {
  width: 100%;
  height: 50px;
  text-transform: none;
  font-size: 18px;
}

.inspection-list-container {
  display: flex;
  gap: 0;
  /* background-color: #f8f8f8; */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #2f2d27;
}

.doneIconModal {
  color: #0c286a;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-right: 5px;
  vertical-align: text-bottom;
  width: 20px;
  height: 20px;
}

.buynow-payment-container {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 1rem;
  max-width: 100%;
  /* height: 100%; */
  background: transparent;
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  margin-bottom: 1rem;
}

.buynow-payment-container-success {
  border: 2px solid green;
  border-radius: 5px;
  padding: 1rem;
  max-width: 100%;
  /* height: 100%; */
  background: transparent;
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  margin-bottom: 1rem;
}

.buynow-payment-container-failure {
  border: 2px solid #aa1945;
  border-radius: 5px;
  padding: 1rem;
  max-width: 100%;
  /* height: 100%; */
  background: transparent;
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  margin-bottom: 1rem;
}
.payment-card-header {
  display: flex;
}

/* .buynow-tablesec {
  padding: 20px 24px 24px;
  background: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  max-width: 861px;
  width: 100%;
  margin: auto;
} */

.buy-now-payment-table-content {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  flex-basis: 90%;
}

.buy-now-payment-table-content .div-invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 1.5rem; */
}

.buy-now-payment-table-content .div-promocode {
  display: flex;
  margin-bottom: 1rem;
}
.buy-now-payment-table-content p {
  font-family: 'matterSemiBold';
  font-weight: 500;
  font-size: 16px;
  color: #545454;
  padding-top: 0px;
  padding-bottom: 5px;
}
.buy-now-payment-table-content span {
  font-weight: 600;
  font-size: 14px;
  color: black;
}
.buy-now-payment-table-content h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 171%;
  /* color: white; */
}
.buy-now-payment-table-content h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 123%;
  /* color: white; */
}
.buy-now-payment-table-content-whatsApp {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  flex-basis: 90%;
  align-items: center;
}
.buy-now-payment-table-content-whatsApp p {
  font-family: 'matterMedium';
  font-weight: 500;
  font-size: 16px;
  color: #545454;
  padding-top: 0px;
  padding-bottom: 5px;
}
.seperator {
  width: 100%;
  height: 1px;
  border: 1px solid #0c286a;
}
.btn-modal-paynow {
  margin-top: 20px;
}

.otp-input input {
  /* width: 48px !important;
  height: 48px !important;
  margin: 10px !important;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%; */
  padding-left: 80px;
}

.buyNow-payment-otp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 5px; */
  height: 100%;
  /* padding: 20px; */
  padding-bottom: 0px;
}
.buyNow-payment-otp input {
  border: 1px solid black;
  /* background: #f2f2fc; */
  width: 50px !important;
  height: 50px !important;
  margin: 10px !important;
  /* border-radius: 8px; */
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
}

.transaction-success-header-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 0.5rem; */
}
.transaction-communication {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 1rem;
}

/* .buy-now-payment-table-content button {
  font-family: "matterSemibold";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0c286a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 14px;
  background: #e0fc00;
  border-radius: 30px;
  text-transform: uppercase;
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 8px;
} */

.sticky-contactUs {
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: green;
  background-color: green;
  /* box-shadow: 0px -5px 8px 0px green; */
  padding: 9px 1.8em;
  z-index: 9999;
  /* display: none; */
}

.sticky-contactUs-mobile {
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: green;
  background-color: green;
  /* box-shadow: 0px -5px 8px 0px green; */
  padding: 9px 1.8em;
  /* z-index: 9999; */
  /* display: none; */
}

.sticky-contactUs-container {
  display: flex;
  justify-content: space-around;
}

.paynow-btn-inspection-list {
  font-family: matterMedium;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.sticky-PayNow-Btn {
  display: none;
}

.sticky-contactUs-container-mobile {
  display: none;
}

/* Target iPhone XR */
@media (min-width: 375px) and (max-width: 414px) and (min-height: 667px) and (max-height: 896px) {
  /* Your CSS styles here */
  .plan-details-btn {
    width: 100%;
    height: 50px;
    text-transform: none;
    font-size: 14px !important;
  }

  .sticky-PayNow-Btn {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    background-color: #0c286a;
    padding: 9px 1.8em;
    z-index: 9999;
  }

  .paynow-btn-inspection-list {
    font-family: matterMedium;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .inspection-list-container {
    display: flex;
    flex-direction: column;
  }
  .inspection-list-container .inspection-prepost-header {
    margin-top: 1rem;
  }
}

@media (max-width: 1330px) {
  .buynow-payment-container {
    width: 100%;
  }
  .plan-details-body {
    display: flex;
    gap: 20px;
    padding: 5px;
    padding-top: 60px;
    flex-direction: column;
  }
  .plan-details-body .left-container {
    width: 60%;
  }
  .plan-details-body .right-container {
    width: 40%;
  }

  .plan-details-buynow-cards-container .plan-details-btn {
    width: 100% !important;
    height: 50px;
    text-transform: none;
    font-size: 14px !important;
  }
}

@media (max-width: 992px) {
  .buynow-payment-container {
    width: 100%;
  }
  .sticky-contactUs-container {
    display: none;
  }

  .sticky-contactUs-container-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 40px; */
  }

  .sticky-PayNow-Btn {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    background-color: #0c286a;
    padding: 9px 1.8em;
    z-index: 9999;
  }

  .inspection-list-container {
    display: flex;
    flex-direction: column;
  }
  .inspection-list-container .inspection-prepost-header {
    margin-top: 1rem;
  }

  .plan-details-body {
    display: flex;
    gap: 20px;
    padding: 5px;
    padding-top: 60px;
    flex-direction: column;
  }
  .plan-details-body .left-container {
    width: 100%;
  }
  .plan-details-body .right-container {
    width: 100%;
  }

  .plan-details-buynow-cards-container .plan-details-btn {
    width: 100%;
    height: 50px;
    text-transform: none;
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .sticky-contactUs {
    padding: 2px 0px;
    color: #003cc5;
    background-color: #003cc5;
  }
  .buynow-payment-container {
    width: 100%;
  }
  .plan-details-buynow-cards-container .premium {
    margin-top: -22px;
  }
  .plan-details-buynow-cards-container .buy-now-card-active {
    padding: 10px;
  }
  .plan-details-buynow-cards-container .buy-now-card {
    padding: 10px;
  }
  .sticky-contactUs-container {
    display: none;
  }
  .plan-details-buyNow-btn-container {
    justify-content: center;
  }
  .sticky-contactUs-container-mobile {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .sticky-PayNow-Btn {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    background-color: #0c286a;
    padding: 9px 1.8em;
    z-index: 9999;
  }

  .inspection-list-container {
    display: flex;
    flex-direction: column;
  }
  .inspection-list-container .inspection-prepost-header {
    margin-top: 1rem;
  }

  .plan-details-body {
    display: flex;
    gap: 20px;
    padding: 5px;
    padding-top: 60px;
    flex-direction: column;
  }
  .plan-details-body .left-container {
    width: 100%;
  }
  .plan-details-body .right-container {
    width: 100%;
  }
  .plan-details-buynow-cards-container {
    justify-content: center;
  }

  .inspection-list-header h5 {
    font-size: 18px;
  }
  .plan-details-buynow-cards-container .plan-details-btn {
    width: 100%;
    height: 50px;
    text-transform: none;
    font-size: 14px !important;
  }
}
@media (max-width: 992px) {
  .plan-details-buynow-cards-container .plan-details-btn {
    width: 100%;
    height: 50px;
    text-transform: none;
    font-size: 14px !important;
  }
}
