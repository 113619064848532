.css-ahj2mt-MuiTypography-root {
  font-family: 'manrope' !important;
  --text-opacity: 1 !important;
  color: rgba(144, 146, 163, var(--text-opacity)) !important;
  font-size: 20px;
  /* padding-top: 1rem; */
}

.css-1v5z4dq-MuiPaper-root-MuiCard-root {
  border-radius: 20px;
}

.MuiTableCell-root {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 5px !important;
}

.MuiList-root-MuiMenu-list {
  display: flex !important;
  flex-direction: column !important;
}

.MuiStep-root {
  /* background-color: red !important; */
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.MuiStepper-root {
  /* background-color: antiquewhite !important; */
}

.MuiStepper-root .MuiStep-root {
  /* display: flex;
  width: 5em !important;
  height: 5em !important;
  padding-left: 20px !important; */
}
.MuiStepConnector-root {
  /* height: 5em !important;
  top: 10px !important; */
}
