.bannerSection {
  padding: 80px 0 60px;
  background: linear-gradient(
    285.57deg,
    rgba(121, 130, 230, 0.1) 0%,
    rgba(186, 189, 217, 0.1) 100%
  );
  position: relative;
}
/* UserSection */

.userSection {
  padding: 0;
  position: relative;
  background: linear-gradient(
      107.01deg,
      rgba(40, 54, 215, 0.1) 0%,
      rgba(142, 147, 215, 0.1) 100%
    ),
    #ffffff;
}
.userSection .userSectionHead {
  padding: 15px 0px 2px;
  /* padding: 15px 0px 64px; */

  position: relative;
  max-width: 1440px;
  margin: auto;
}

/* .userSection .userContent {
  max-width: 740px;
} */

.userBannerSlicker {
  display: none;
}

.userSection .userContent label {
  display: flex;
  gap: 4px;
  align-items: flex-end;
  background-color: rgba(121, 130, 230, 0.2);
  border-left: none;
  border-radius: 0px 8px 8px 0px;
  width: fit-content;
  max-width: 100%;
  justify-content: center;
  padding: 6px 12px 6px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(38, 44, 110, 0.8);
  margin-bottom: 24px;
  margin-top: 80px;
  border-radius: 24px;
}

.userSection .userContent h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  color: #eb6d47;
  max-width: 740px;
}

.userSection .userContent span {
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  color: #262c6e;
}

.userSection .userContent .userBannerHeading span {
  font-weight: 400;
}

.userSection .userContent p {
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
  color: #262c6e;
  padding-top: 16px;
  max-width: 510px;
}
.userSection .userContent .userBannerWarrent p {
  font-size: 14px;
}

.userSection .userContent p span {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #eb6d47;
}

.userSection .userContent label {
  border-left: none;
  border-radius: 24px;
}

.inspectionImg {
  height: 304px;
  width: 100%;
  object-fit: cover;
}

.inspectionImgRightTop {
  border-top-right-radius: 24px;
}

.inspectionImgLeftBottom {
  border-bottom-left-radius: 24px;
}

.inspectionImgRightBottom {
  border-bottom-right-radius: 24px;
}

.bannerImage {
  position: absolute;
  top: 91px;
  right: 0;
  bottom: 0;
  max-width: 684px;
}

.bannerInputImage {
  border-radius: 16px;
  border: 1px solid #8e93d7;
  background: linear-gradient(
      107.01deg,
      rgba(142, 147, 215, 0.1) 0%,
      rgba(40, 54, 215, 0.1) 100%
    )
    #ffffff;
}

.bannerInputImage2 {
  border-radius: 16px;
  border: 1px solid #8e93d7;
  background: linear-gradient(
    107.01deg,
    rgba(142, 147, 215, 0.1) 0%,
    rgba(40, 54, 215, 0.1) 100%
  );
}

.bannerWarranty {
  padding-top: 65px;
}

.bannerInputImage span {
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(38, 44, 110, 0.7);
}

.bannerInputSection {
  max-width: 525px;
  padding-top: 64px;
}

.bannerInputSection2 {
  max-width: 342px;
  padding-top: 31px;
  display: none;
}

.inputWrapper input[type='text'] {
  max-width: 525px;
  width: 100%;
  background: #ffffff;
  background-clip: padding-box;
  border-bottom: none;
  border-radius: 16px;
  padding: 29px 0px 29px 24px;
  border-top: 1px solid #8e93d7;
  outline: none;
  border-right: none;
  border-left: none;
}

::placeholder {
  color: rgba(70, 72, 104, 0.8);
  opacity: 0.6;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
}

.inputWrapper2 ::placeholder {
  color: rgba(70, 72, 104, 0.8);
  opacity: 0.6;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
}

.report-btn {
  background: #eb6d47;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  padding: 14.5px 17.65px 14.5px 24px;
  gap: 8px;
  position: absolute;
  right: 16px;
  bottom: 50%;
  transform: translateY(50%);
  border: none;
  cursor: pointer;
  font-family: 'matter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  align-items: center;
}

.report-btn2 {
  background: #eb6d47;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  padding: 14.5px 17.65px 14.5px 24px;
  gap: 8px;
  border: none;
  cursor: pointer;
  font-family: 'matter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  max-width: 310px;
  width: 100%;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
}

.inputWholeWrapper {
  border-radius: 16px;
  background: #ffffff;
  padding: 16px;
  position: relative;
}

.inputWrapper2 input[type='text'] {
  max-width: 325px;
  width: 100%;
  background: linear-gradient(
      285.57deg,
      rgba(121, 130, 230, 0.1) 0%,
      rgba(186, 189, 217, 0.1) 100%
    ),
    #ffffff;
  background-clip: padding-box;
  border-bottom: none;
  border-radius: 6px;
  padding: 12px;
  border: 1px solid #8e93d7;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* position: relative; */
}

.inputWrapper2 .qr-responsive {
  position: absolute;
  top: 28px;
  right: 28px;
}

.inputWrapper {
  position: relative;
}

.inputHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0px 8px 12px;
}

.inputHeader div {
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(38, 44, 110, 0.7);
}

.inputHeader2 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0px 8px 12px;
}

.inputHeader2 div {
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(38, 44, 110, 0.7);
}

.userBannerContentContainer {
  display: none;
}

.bannerUnbiased {
  padding-top: 16px !important;
  font-size: 20px !important;
  max-width: 510px !important;
}

/* Inspection Section */

.inspection {
  padding: 96px 0px 120px 0px;
  background: #fafafa;
}

.inspectionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 24px;
  overflow: hidden;
  background: white;
}

.inspectionContainer img {
  display: inline-block;
}

.inspectionSection h2 {
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  color: #262c6e;
  max-width: 524px;
  padding: 56px 0px 0px 56px;
}

.inspectionSection h2 span {
  color: #eb6d47;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
}

.inspectionSection {
  border-radius: 24px;
  display: flex;
  flex-basis: 50%;
}
.inspectionSection.inspectionSectionPhase3Big {
  flex-basis: 100%;
}

.inspectionSection img {
  max-width: 100%;
}

.inspectionProfile {
  position: relative;
  width: 50%;
}

.inspectionProfileCard {
  position: absolute;
  bottom: 10%;
  left: 3%;
  display: flex;
  gap: 12px;
}

.inspectionProfileCard p {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}

/* .inspectionProfile img {
  filter: gray; 
  -webkit-filter: grayscale(1); 
  -webkit-transition: all 0.1s ease-in-out;
  vertical-align: bottom;
} */
.inspectionProfile img {
  filter: brightness(0.5) grayscale(1);
  -webkit-filter: brightness(0.7) grayscale(1);
  -webkit-transition: all 0.1s ease-in-out;
  vertical-align: bottom;
}

.inspectionProfile img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1.01);
}

.inspectionProfile:hover .inspectionText {
  color: black;
  font-weight: 500;
}

.userBannerTransmission {
  display: inline-block;
}

.userBannerContentSections div {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #262c6e;
  /* padding: 27px 0px 24px 6.44px; */
}
.userBannerContentSections {
  display: flex;
  gap: 4px;
  margin-top: 27px;
  align-items: flex-start;
}

.userBannerContentSections:last-of-type {
  margin-top: 24px;
}

/* .bannerWarranty {
  padding-top: 43px;
} */

/* Technology Section */

.technology {
  background: linear-gradient(106.55deg, #262c70 2.27%, #0c0f32 114.46%);
  position: relative;
  z-index: 1;
  padding: 96px 0px 96px 0px;
}

.technology::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/images/home/userTechnologyDots.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  opacity: 0.7; /* adjust the opacity to your liking */
  z-index: -99;
  clip-path: polygon(0 80%, 100% 64%, 100% 100%, 0 100%);
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 20%,
    black 100%
  );
  mask-image: linear-gradient(to bottom, transparent 0%, black 20%, black 100%);
}
.technologySection {
  flex-basis: 50%;
}

.technologyContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
}

.technologyHeadingDesk {
  font-family: 'matter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
  padding-top: 45px;
}

.technologyHeadingDesk span {
  color: #eb6d47;
  font-family: 'matter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
}

.technologyList {
  padding-top: 40px;
}

.technologyList div {
  font-family: 'matter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.8);
  padding-left: 15px;
  max-width: 504px;
}

.technologyList li {
  padding-bottom: 24px;
  display: flex;
}

.explore-btn {
  padding: 12.12px 13.04px 14.5px 18.63px;
  color: #eb6d47;
  background: linear-gradient(106.55deg, #262c70 2.27%, #0c0f32 114.46%);
  border: 1px solid #eb6d47;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 35.33px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: 'matter', sans-serif;
  font-style: normal;
  align-items: center;
}

button {
  padding-top: 37.43px;
}

.usertechnologyImage {
  width: 100%;
  flex-basis: 50%;
}

.bannerHeading.newContent {
  display: flex;
  max-width: 100%;
  padding-bottom: 0;
}
.bannerHeading {
  padding-bottom: 154px;
  max-width: 580px;
  width: 100%;
}
.bannerSection .slick-list {
  width: 832px;
  max-width: 100%;
}
.bannerSection .slick-slide {
  margin-right: 24px !important;
}
.bannerContent label {
  display: flex;
  gap: 4px;
  align-items: flex-end;
  background-color: rgba(121, 130, 230, 0.2);
  border-left: 3px solid #7982e6;
  border-radius: 0px 8px 8px 0px;
  width: fit-content;
  max-width: 100%;
  justify-content: center;
  padding: 2px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(38, 44, 110, 0.8);
  margin-bottom: 20px;
  margin-top: 80px;
}
.bannerContent label img {
  transform: rotateY(180deg);
  padding-bottom: 2px;
}
.newContent .bannerSlider img {
  margin-bottom: 24px;
  display: block;
}
.heroImage {
  width: 1099px;
  max-width: 100%;
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
}

.heroImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bannerContent h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  color: #262c6e;
  margin-bottom: 48px;
  font-weight: 500;
}

.bannerContent h1 span {
  font-weight: 700;
  color: #eb6d47;
}
.bannerList {
  padding-top: 27px;
  display: none;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 27px;
}
.bannerList li {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #262c6e;
  padding-left: 26px;
  position: relative;
}
.bannerList li::before {
  content: url(../../assets/images/home/checkedCircle.svg);
  position: absolute;
  left: 0;
  top: -3px;
}

.bannerContent .cta-button {
  width: 205px;
  max-width: 100%;
  gap: 4px;
}
.marquee {
  height: 48px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  width: 832px;
}

.marqueeLogos {
  width: max-content;
  position: absolute;
  overflow-x: hidden;
  top: 0;
  left: 0;

  animation: moveSlideshow 5s linear infinite;
}

.marqueeLogos img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 32px;
}

.bannerWarranty p {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: rgba(38, 44, 110, 0.8);
  /* margin-bottom: 16px; */
}

.bannerReport {
  width: 525px;
  max-width: 100%;
  position: relative;
  margin: auto;
  margin-bottom: 30px;
}

.bannerReport .report {
  background: linear-gradient(
      107.01deg,
      rgba(142, 147, 215, 0.1) 0%,
      rgba(40, 54, 215, 0.1) 100%
    ),
    #ffffff;
  color: white;
  border-radius: 16px;
  border: 1px solid #8e93d7;
  margin-bottom: 8px;
}

.reportHeading {
  display: flex;
  align-items: center;
  padding: 10px 12px 8px;
}

.reportHeading p {
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(38, 44, 110, 0.7);
  padding-left: 12px;
}

.getReport {
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #8e93d7;
}

.getReport input {
  border: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: rgba(70, 72, 104, 0.8);
  opacity: 0.6;
}
.getReport input:focus-visible {
  outline: none;
}

.getReport .inputMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-basis: 65%;
}

.getReport button.cta-button {
  background: #eb6d47;
  border: none;
  width: 157px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  gap: 8px;
  flex-basis: 35%;
  cursor: pointer;
}

.bannerIconsLeft {
  position: absolute;
  left: 0;
  bottom: 0;
}

.bannerIconsRight {
  position: absolute;
  right: 0;
  bottom: 0;
}

.carNumberErrorDesk {
  background: #fae8e8 !important;
  border-radius: 8px;
  padding: 4px 24px;
}
.error-position {
  position: absolute;
  bottom: -28px;
  width: 100%;
}
.carNumberErrorDesk,
.carNumberErrorMobile {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #f81c1c;
  display: none;
}

.carNumberErrorDesk.active,
.carNumberErrorMobile.active {
  display: initial;
}

/* Save time Section */
.saveTime {
  position: relative;
  padding: 0;
}
.saveTime .commonHeading {
  /* position: sticky; */
  top: 0;
  background: #fff;
  margin: 0;
  padding-bottom: 60px;
  z-index: 9;
  padding-top: 70px;
}
.saveTime .commonHeading h2 {
  color: #262c6e;
  width: 100%;
  max-width: 700px;
}
/* .saveTime .commonHeading span {
  display: block;
} */
.saveTimeDetails {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 90px;
  gap: 10px;
}
.saveTimeList {
  /* position: sticky; */
  height: 518px;
  top: 251px;
}
.saveTimeContentWrapper:last-child {
  padding-bottom: 0;
}
.saveTimeList li {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #666666;
  padding: 24px;
}
.saveTimeList li {
  width: 480px;
  max-width: 100%;
  cursor: pointer;
}
.saveTimeList .selected a {
  color: rgba(40, 54, 215, 1);
  border-radius: 18px;
  position: relative;
  -webkit-background-clip: text !important;
  background: linear-gradient(107.01deg, #2836d7 0%, #8e93d7 100%);
  -webkit-text-fill-color: none;
}
.saveTimeList svg path {
  fill: #666666;
}
.saveTimeList .selected svg path {
  fill: url(#paint0_linear_1525_6429);
}
.saveTimeList .selected {
  position: relative;
  background: #f6f6fd;
  border: 1px solid transparent;
  border-radius: 18px;
}
.saveTimeList .selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 18px;
  border: 1px solid transparent;
  background: linear-gradient(
    to right,
    rgba(40, 54, 215, 1),
    rgba(142, 147, 215, 1)
  );
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
.saveTimeContentWrapper {
  /* padding-bottom: 36px; */
  opacity: 0;
  transition: all 1.5s;
  display: none;
}
.saveTimeContentWrapper.activeDesktop {
  opacity: 1;
  display: block;
}
.saveTimeContent {
  width: 628px;
  max-width: 100%;
}
.saveTimeContent img {
  padding-bottom: 24px;
  display: block;
}
.saveTimeContent h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: #181b44;
  padding-bottom: 12px;
}
.saveTimeContent h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: #eb6d47;
  padding-bottom: 12px;
  position: relative;
  padding-left: 22px;
}
.saveTimeContent h5::before {
  content: url(../../assets/images/home/withWD.svg);
  position: absolute;
  left: 0;
  top: 2px;
}
.saveTimeContent p {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7c7fa7;
  padding-bottom: 24px;
}
.saveTimeContent p:last-child {
  padding: 0;
}
.saveTimeAccordionQuestion p {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #666666;
  padding: 16px 0;
  position: relative;
}
.saveTimeAccordionQuestion p::after {
  content: url(../../assets/images/home/downArrow.svg);
  position: absolute;
  right: 1px;
  top: 16px;
  transition: all 0.5s ease-in-out;
}
.selected .saveTimeAccordionQuestion p::after {
  content: url(../../assets/images/home/upArrow.svg);
  transform: rotate(180deg);
  top: 8px;
}
.saveTimeAccordionAnswer img {
  padding-top: 8px;
  padding-bottom: 24px;
}
.saveTimeAccordionAnswer h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: #181b44;
  padding-bottom: 12px;
}
.saveTimeAccordionAnswer h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: #eb6d47;
  padding-bottom: 12px;
  position: relative;
  padding-left: 22px;
}
.saveTimeAccordionAnswer h5::before {
  content: url(../../assets/images/home/withWD.svg);
  position: absolute;
  left: 0;
  top: 2px;
}
.saveTimeAccordionAnswer p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  padding-bottom: 34px;
}
.saveTimeAccordionAnswer p:last-child {
  padding-bottom: 16px;
}
.saveTimeAccordionAnswer h4:last-child {
  padding-top: 34px;
}
.saveTimeAccordionAnswer h5:last-child {
  padding-top: 34px;
}
.saveTimeAccordion {
  border-bottom: 0.2px solid #666666;
}
.selected .saveTimeAccordionQuestion p {
  color: rgba(40, 54, 215, 1);
  border-radius: 18px;
  position: relative;
  -webkit-background-clip: text;
  background-image: linear-gradient(107.01deg, #2836d7 0%, #8e93d7 100%);
  -webkit-text-fill-color: none;
}
.saveTimeAccordionAnswer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.selected .saveTimeAccordionAnswer {
  opacity: 1;
  max-height: 100%;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.saveTimeMobileContent {
  padding-bottom: 84px;
  display: none;
}
.saveTimeAccordionQuestion svg path {
  fill: #666666;
}

.selected .saveTimeAccordionQuestion svg path {
  fill: #6455dc;
}

.planCard .planMobileImage {
  display: none;
}
.plan {
  background: linear-gradient(105.15deg, #333c99 25.5%, #555fd7 64.88%);
  position: relative;
}
.plan .commonHeading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}
.plan .commonHeading a {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}
.plan .commonHeading a img {
  transition: all 0.5s ease-in-out;
}
.plan .commonHeading a::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #eb6d47;
  transition: width 0.5s;
  position: absolute;
  bottom: 0;
}
.plan .commonHeading a:hover img {
  transform: translateX(5px);
}
.plan .commonHeading a:hover:after {
  width: 100%;
}
.plan .commonHeading h2 {
  width: 588px;
  max-width: 100%;
}
.plan .commonHeading h2 {
  color: #ffffff;
}
.planContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  padding-bottom: 17em;
}
.planCard {
  display: flex;
  justify-content: space-between;
  padding: 0 0 38px 40px;
  background: linear-gradient(288.97deg, #fefbfa 0%, #ffefeb 100%);
  box-shadow: 0px -6px 24px rgb(0 0 0 / 6%);
  border-radius: 18px 18px 0 0;
  background-repeat: no-repeat;
  background-position: top right;
  position: sticky;
  min-height: 396px;
}
.planCard.card-one {
  width: 95%;
  margin: auto;
  top: 5em;
  margin-bottom: -5em;
}
.planCard.card-two {
  width: 97.5%;
  margin: auto;
  top: 11em;
  margin-bottom: -11em;
  margin-top: 3em;
}
.planCard.card-three {
  top: 17em;
  margin-bottom: -17em;
  margin-top: 9em;
  border-radius: 18px;
}
/* .planCard {
  padding: 36px 42px 60px;
  background: linear-gradient(288.97deg, #fefbfa 0%, #ffefeb 100%);
  box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  position: relative;
} */
/* .planCard .planLines {
  top: 0;
  z-index: 1;
}
.planCard img {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  border-bottom-right-radius: 18px;
  z-index: 9;
} */
.planCard h3 {
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #181b44;
  padding-bottom: 24px;
  max-width: 350px;
  padding-top: 40px;
}
.planCard img {
  border-bottom-right-radius: 18px;
  position: absolute;
  top: 0;
  right: 0;
}
.planCard p {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  max-width: 502px;
  padding-bottom: 40px;
}
.planCard:last-child li {
  width: 46%;
}
.planCard ul {
  max-width: 620px;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 24px;
  /* padding-left: 12px; */
}
.planCard ul li {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #5c5c5c;
  /* flex-basis: 50%; */
  padding-left: 28px;
  position: relative;
}
.planCard ul li::before {
  content: url(../../assets/images/home/secureTick.svg);
  position: absolute;
  left: 0;
  top: 3px;
  height: 20px;
}

/* Saving Comparission Section */
.saving {
  background: linear-gradient(
      180deg,
      rgba(121, 130, 230, 0.12) 0%,
      rgba(121, 130, 230, 0) 100%
    ),
    fixed, url(../../assets/images/home/savingBg.png);
  background-repeat: no-repeat;
  background-position: bottom;
}
.saving .commonHeading {
  width: 865px;
  max-width: 100%;
}
.savingImage {
  width: 1120px;
  max-width: 100%;
  margin: auto;
}
.saving .commonHeading h2 {
  color: #262c6e;
}
.before-after-slider img {
  max-width: none;
}
.before-after-slider__delimiter {
  background: rgba(15, 35, 77, 0.5) !important;
  width: 2px !important;
}
.before-after-slider__delimiter-icon {
  background-image: url(../../assets/images/home/slideCompare.svg);
  width: 50px !important;
  height: 50px !important;
}

/* Warranty Table Sectio */
.warrantyTable {
  background: linear-gradient(
      180deg,
      rgba(121, 130, 230, 0.12) 0%,
      rgba(121, 130, 230, 0) 100%
    ),
    #ffffff;
}
.warrantyTable .commonHeading {
  max-width: 865px;
}
.warrantyTable .commonHeading h2 {
  color: #262c6e;
}
.warrantyTableContent table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.warrantyTableContent table th {
  padding: 34px 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
  color: #262c6e;
  background-color: #e9ebfc;
}
.warrantyTableContent table th:first-child {
  border-top-left-radius: 16px;
}
.warrantyTableContent table th:last-child {
  border-top-right-radius: 16px;
}
.warrantyTableContent table tr {
  border-bottom: 1px solid #d4d8f7;
}
.warrantyTableContent table td {
  padding: 24px 24px 24px 64px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
  position: relative;
  width: 50%;
  vertical-align: top;
}
.warrantyTableContent table td img {
  padding-right: 8px;
}
.warrantyTableContent table td:first-child::before {
  content: url(../../assets/images/home/wrong.svg);
  position: absolute;
  left: 24px;
}
.warrantyTableContent table td:last-child::before {
  content: url(../../assets/images/home/right.svg);
  position: absolute;
  left: 24px;
}
.warrantyTableContent table tr:first-child {
  border: none;
}

/* Service Setion */
.service {
  background: linear-gradient(105.15deg, #333c99 25.5%, #555fd7 64.88%);
}
.service .commonHeading {
  width: 965px;
  max-width: 100%;
}
.service .commonHeading h2 {
  color: #fff;
}
.serviceContent {
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}
.serviceContentCard {
  padding: 222px 33px 46px 41px;
  background: linear-gradient(288.97deg, #fefbfa 0%, #fff7f5 100%);
  border-radius: 18px;
  position: relative;
  width: 579px;
  max-width: 100%;
}
.serviceContentImage {
  position: absolute;
  top: 0;
  display: block;
  left: 0;
}
.serviceContentCard h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  color: #262c6e;
  margin-bottom: 16px;
}
.serviceContentCard p {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #464868;
  max-width: 433px;
  padding-bottom: 24px;
}
.serviceContentCard a {
  display: flex;
  gap: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff8966;
  position: relative;
  width: fit-content;
}
.serviceContentCard a:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  width: 0;
  height: 1px;
  background: #eb6d47;
  transition: width 0.5s;
}
.serviceContentCard a:hover::after {
  width: 100%;
}
.serviceContentCard a img {
  transition: all 0.5s ease-in-out;
}
.serviceContentCard a:hover img {
  transform: translateX(5px);
}

/* Calculate Section */
.calculate {
  background-color: #e8e9f7;
  position: relative;
}
.calculate .commonHeading {
  max-width: 775px;
  width: 100%;
}
.calculate .commonHeading h2 {
  color: #262c6e;
}
.calculateContent {
  /* display: flex;
  justify-content: space-between; */
  width: 1200px;
  margin: auto;
  max-width: 100%;
}
.calculateContentCard {
  border-radius: 16px;
  position: relative;
  border: 1px solid #2836d7;
  background-color: #e8e9f7;
  max-width: 579px !important;
  /* max-width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  background-clip: padding-box;
}
.slick-slide:first-child .calculateContentCard {
  background-image: url(../../assets/images/home/circleBg.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}
.slick-slide:last-child .calculateContentCard {
  background-image: url(../../assets/images/home/rectangleBg.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}
.calculateContentCard img {
  z-index: 9;
}
/* .calculateContentCard:first-child .calculateContentCardBg {
  right: 14px;
} */
.calculateContentCard img {
  float: right;
  border-bottom-right-radius: 16px;
}
.calculateContentCardBg {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9;
}
/* .calculateContentCard:after{
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  left: -2px;
  bottom: -2px;
  z-index: -1;
  border-radius: 16px;
  background: linear-gradient(to right, #2836D7 100%, #8E93D7 100%);
} */
.calculateContentCard p {
  padding: 40px 40px 0;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #262c6e;
  min-height: 220px;
}
.calculate .cta-button {
  justify-content: space-between;
  margin-top: 42px;
  padding: 24px 20px;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
}
.calculate .cta-button img {
  width: 32px;
}
.calculate .slick-slide {
  max-width: 579px !important;
  margin-right: 42px;
}
.calculate .slick-slide:last-child {
  margin: 0;
}
.modal {
  height: 100%;
  background: #f7f7fd;
  /* transform: translateX(100%); */
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.6s ease-in-out;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  padding: 56px 0;
  right: -100%;
}
.modal.open {
  right: 0;
}
.modal .commonHeading {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
}
.modal .commonHeading h2 {
  max-width: 580px;
  width: 100%;
}
.modal .commonHeading img {
  cursor: pointer;
}
.modalForm {
  max-width: 670px;
  width: 100%;
}
.modalContainer {
  background: #e8e9f7;
  /* padding: 42px; */
  border: 1px solid #ffffff;
  border-radius: 18px;
  display: flex;
}
.modalForm {
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 42px 48px 82px 42px;
}
.modalForm .formGroup label {
  padding-bottom: 6px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
  display: block;
}
.modalForm .formGroup select {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding: 12px;
  background-color: transparent;
  border: 1px solid #808080;
  border-radius: 6px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.formSelect {
  position: relative;
}
.formSelect::after {
  content: url(../../assets/images/service-network/downArrow.svg);
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: 0.5;
}
.modalForm .formField {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.modalForm .formGroup {
  width: 100%;
}
.modalForm .formGroup div {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 12px;
}
.modalForm .formGroup p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}
.modalForm .formGroup p:first-child {
  padding-left: 0;
}
.modalForm .formGroup p {
  padding: 12px;
}
.modalForm .formField input {
  padding: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);
  border: 1px solid #808080;
  border-radius: 6px;
  background-color: transparent;
}
.modalContent {
  padding: 24px 48px 32px;
  background: linear-gradient(107.14deg, #333c99 0%, #555fd7 0.02%);
  border-radius: 18px;
  width: 100%;
  max-width: 528px;
}
.modalContent .carModal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  opacity: 0.8;
}
.modalContent .carModal p {
  padding: 0;
  display: block;
  border: none;
}
.modalContent p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  padding: 32px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
}
.modalContent p span {
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  color: rgba(255, 255, 255, 0.9);
}
.modalContent div:last-child p {
  border: none;
}
.modal .modalMessage p {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #262c6e;
  align-items: flex-start;
  gap: 8px;
  justify-content: flex-start;
}
.modal .modalMessage {
  padding: 12px 16px;
  background: #f7f7fd;
  border-radius: 6px;
}
/* Checkbox Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #5560d8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.modalForm .formGroup .activeLabel {
  color: #5560d8;
}
input:checked + .slider {
  background-color: #5560d8;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Range Slider */
.range .slideProgress {
  -webkit-appearance: none;
  background: url(../../assets/images/home/slider.svg);
  outline: none;
  border: none !important;
  opacity: 1;
  -webkit-transition: 0.2s;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.2s;
  max-width: 100%;
  /* background-size: cover; */
}
.slideProgress::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 5px solid #5560d8;
  /* z-index: 99; */
}

.slideProgress::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}
.range {
  display: flex;
  padding-right: 30px;
  gap: 24px;
}
.range input {
  max-width: 132px;
  width: 100%;
}

/* Warranty Section */
.warranty {
  /* padding-bottom: 139px; */
  /* background: linear-gradient(
    180deg,
    rgba(121, 130, 230, 0.12) 0%,
    rgba(121, 130, 230, 0) 100%
  ); */
  background-color: #ffffff;
}
.warranty .commonHeading {
  max-width: 100%;
  width: 640px;
  margin-bottom: 46px;
}
.warranty .commonHeading h2 {
  color: #262c6e;
}
.warrantyDetails {
  display: flex;
  justify-content: space-between;
}
.warrantyContent {
  padding-top: 14px;
}
.warrantyAccordion:first-child {
  margin-top: 0;
}
.warrantyDetailsImage {
  display: none;
}
.warrantyDetailsImage.active {
  display: block;
}
.warrantyDetailsImage img {
  width: 567px;
  max-width: 100%;
}
.warrantyAccordion {
  padding-bottom: 24px;
  margin-top: 32px;
  max-width: 515px;
  margin-left: 44px;
  position: relative;
  cursor: pointer;
}
.warrantyAccordion svg {
  position: absolute;
  left: -44px;
  top: 0;
}
.warrantyAccordionAnswer img {
  display: none;
}
.warrantyAccordionQuestion p {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  color: #262c6e;
}
.warrantyProgress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(40, 54, 215, 0.1),
    rgba(142, 147, 215, 0.1)
  );
}
div#progress1 {
  display: none;
}

.bannerHeading label {
  align-items: flex-end;
  background-color: rgba(121, 130, 230, 0.2);
  border-left: 3px solid #7982e6;
  border-radius: 0 8px 8px 0;
  color: rgba(38, 44, 110, 0.8);
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 4px;
  justify-content: center;
  line-height: 24px;
  margin-bottom: 20px;
  margin-top: 80px;
  max-width: 100%;
  padding: 8px 24px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.bannerHeading h1 span {
  color: #262c6e;
  font-weight: 400;
}
.bannerHeading p {
  color: rgba(38, 44, 110, 0.8);
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 40px;
}
.bannerHeading p b {
  color: #eb6d47;
}
.bannerHeading .cta-button {
  gap: 4px;
  max-width: 100%;
  width: 205px;
}

.bannerHeading h1 {
  color: #eb6d47;
  font-size: 64px;
  font-weight: 600;
  line-height: 108%;
  margin-bottom: 16px;
}

.warrantyProgress.addProgress {
  display: block !important;
}
/* .progress{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(40, 54, 215, 0.1),
    rgba(142, 147, 215, 0.1)
  );
} */
.warrantyProgress .progress {
  width: 0;
}
.warrantyProgress .warrantyProgressBar {
  height: 100%;
  width: 0%;
  animation: fill-bar 5s linear;
}
.active .warrantyAccordionAnswer {
  display: block;
}
.active .warrantyProgress {
  background-color: #eaebfa;
}
.active .warrantyProgress .warrantyProgressBar {
  background-color: #eb6d47;
}
.active svg rect {
  fill: #ffe7e0;
}
.active svg path {
  fill: #ff825d;
}
.warrantyAccordionAnswer {
  display: none;
}
.warrantyAccordionAnswer p {
  font-weight: 500;
  font-size: 18px;
  line-height: 164%;
  color: #464868;
  padding-top: 12px;
  max-width: 450px;
  min-height: 71px;
}

/* Buy back section */

.buyback {
  background: #eb6d47;
  display: flex;
  width: 100%;
  align-items: center;
}
.buybackWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buybackContent {
  flex-basis: 55%;
}
.securedDesk,
.securedMobile {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #0eb53c;
  display: inline-flex;
  align-items: center;
  background: #ffffff;
  border-radius: 48px;
  padding: 8px 24px;
  margin-bottom: 32px;
}
.securedDesk img,
.securedMobile img {
  margin-right: 8px;
}
.buyback h2 {
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 32px;
}
.buyback h2 span {
  font-weight: 600;
}
.buybackList {
  padding-bottom: 16px;
}
.buyback li {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.buyback li img {
  margin-right: 12px;
}
.buyback li span {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #e8e8e8;
}
.buybackButtons {
  display: flex;
  align-items: center;
}
.buybackButtons a {
  display: inline-block;
  text-align: center;
}
.buybackButtons a:first-child {
  margin-right: 16px;
  background: #ffffff;
}
.buybackButtons a:last-child {
  border: 1px solid #ffffff;
  background: transparent;
}
.buybackButtons a:last-child span {
  color: #ffffff;
}
.buybackButtons a:first-child span {
  background: linear-gradient(107.01deg, #2836d7 0%, #8e93d7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.buybackImage {
  flex-basis: 45%;
}
.buyBackSlider {
  display: none;
}

.userSection .tabSize {
  display: none;
}
.desktopSize {
  display: block;
}

/* buyback section end */

@media (min-width: 769px) {
  .buybackImage .securedMobile,
  .buybackImage .headingMobile {
    display: none;
  }
  .bannerContent label {
    margin-inline: auto;
  }
  .bannerContent h1 {
    text-align: center;
  }
  .carNumberErrorMobile {
    display: none;
  }
  .getReport .inputMobile .qrCode {
    display: none;
  }
}

@media (max-width: 1250px) {
  .calculate .slick-track {
    margin-left: 24px;
  }
  .saveTime .commonHeading h2 {
    font-size: 40px;
  }
  .planCard {
    padding-bottom: 32px;
    min-height: 418px;
  }
  .planCard img {
    max-width: 550px;
  }
  .planCard p {
    max-width: 400px;
  }

  .bannerImagePhase3 {
    max-width: 604px;
  }

  .userSection .bannerSlider {
    max-width: 495px;
  }
  .userSection .userContent h1 {
    max-width: 510px;
  }
  .userBannerSlicker {
    display: block;
    max-width: 605px;
  }
}

@media (max-width: 1150px) {
  .bannerImage {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 45%;
  }
}

@media (max-width: 1024px) {
  .userBannerContentContainer {
    display: block;
    margin-bottom: 27px;
  }
  .desktopSize {
    display: none;
  }
  .userSection .userContent h1 {
    max-width: 100%;
  }
  .userSection .userContent p {
    max-width: 100% !important;
  }
  .userBannerSlicker {
    display: block;
    max-width: 100%;
  }
  .heroImage {
    display: none;
  }
  .userSection .bannerSlider {
    max-width: 420px;
  }
}

@media (max-width: 992px) {
  .inspectionImgRightTop {
    border-top-right-radius: 0;
  }

  .inspectionImgLeftBottom {
    border-bottom-left-radius: 0;
  }

  .inspectionImgRightBottom {
    border-bottom-right-radius: 0;
  }

  .inspectionProfile img:hover {
    transform: scale(1);
  }

  .inspectionSectionPhase3Big {
    /* padding: 0 8px; */
  }
  /* .inspectionImg{
    width: 100%;
  } */

  .userSection .userSectionHead {
    padding: 0;
  }

  .bannerIconsRight,
  .bannerIconsLeft {
    display: none;
  }
  .saveTimeDetails {
    display: none;
  }
  .userSection.bannerSection {
    overflow: hidden;
  }
  .userSection.bannerSection .newContent .slick-list {
    width: 100%;
    overflow: visible;
  }
  .newContent .bannerSlider {
    padding-top: 32px;
    max-height: 170px;
    padding-bottom: 12px;
  }
  .newContent .bannerSlider img {
    margin-bottom: 0;
  }
  .bannerHeading.newContent {
    flex-wrap: wrap;
  }
  .bannerSlider .slick-list::before {
    content: none;
  }
  .bannerSlider .slick-list::after {
    content: none;
  }
  .modalContainer {
    flex-wrap: wrap;
  }
  .modalContent {
    max-width: 100%;
  }
  .planCard p {
    max-width: 374px;
  }
  .planCard .planMobileImage {
    display: block;
    max-width: 360px;
  }
  .saveTime .commonHeading {
    position: inherit;
  }
  .saveTimeMobileContent {
    display: block;
  }

  .cardSeperator {
    display: none;
  }

  .planCard img {
    display: none;
  }
  .planCard {
    padding-bottom: 32px;
    min-height: 432px;
  }

  /* .userSection .tabSize {
    display: block;
  } */
  .userSection .bannerSlider {
    display: none;
  }
  .userSection .bannerSection {
    height: 100%;
  }

  /* .bannerInputSection {
    padding-top: 0;
  } */

  /* UserSection Section */
  .userSection {
    padding: 38px 0px 11px 0px;
  }

  .userSection .userContent h1.userBannerHeading {
    font-weight: 500;
  }

  .userSection .userContent h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    color: #eb6d47;
  }
  .assistance .mobileImage .userSection .userContent span {
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    color: #262c6e;
  }

  .userSection .userContent p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #262c6e;
    padding-top: 16px;
    margin-bottom: 16px;
  }

  .userSection .userContent p span {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #eb6d47;
  }

  .bannerUnbiased {
    font-size: 16px !important;
  }

  .bannerWarranty {
    padding-top: 23px;
  }

  ::placeholder {
    color: rgba(70, 72, 104, 0.8);
    opacity: 0.6;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .tabSize {
    display: none;
  }

  /* Technology Section */

  .technology {
    padding: 48px 0px 48px 0px;
  }

  .technologyContainer {
    display: flex;
    flex-direction: column;
  }

  .technologySection {
    order: 2;
    flex-basis: 100%;
  }
  .usertechnologyImage {
    flex-basis: 100%;
  }

  .technologyHeadingDesk {
    font-family: 'matter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #ffffff;
    padding-top: 25px;
  }

  .technologyHeadingDesk span {
    color: #eb6d47;
    font-family: 'matter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  }

  .technologyList {
    padding-top: 32px;
  }

  .technologyList div {
    font-family: 'matter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.8);
    padding-left: 15px;
    max-width: 504px;
  }

  .technologyList li {
    padding-bottom: 20px;
  }

  .explore-btn {
    padding: 12.12px 13.04px 14.5px 18.63px;
    color: #eb6d47;
    background: linear-gradient(106.55deg, #262c70 2.27%, #0c0f32 114.46%);
    border: 1px solid #eb6d47;
    display: flex;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 23.02px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: 'matter', sans-serif;
    font-style: normal;
  }

  button {
    padding-top: 20.96px;
  }

  /* Inspection */
  .inspection {
    padding: 48px 16px 48px 16px;
    background: #fafafa;
  }

  .inspectionSection {
    border-radius: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    flex-basis: auto;
  }
  .inspectionSectionPhase3 {
    justify-content: flex-start;
  }

  .inspectionSection h2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #262c6e;
    padding: 24px;
  }

  .inspectionSection h2 span {
    color: #eb6d47;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  }

  .inspectionProfile img {
    filter: gray; /* IE5+ */
    -webkit-filter: none; /* Webkit Nightlies & Chrome Canary */
    -webkit-transition: all 0.1s ease-in-out;
  }
}

/* Media Query */
@media (max-width: 991px) {
  .securedDesk,
  .securedMobile {
    padding: 2px 12px;
  }
  .securedDesk img,
  .securedMobile img {
    margin-right: 6px;
    max-width: 16px;
  }
  .securedDesk,
  .securedMobile {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .userSection.bannerSection .bannerSlider .slick-slide {
    margin-right: 0 !important;
  }
  .cardsContainer {
    padding-right: 24px;
  }
}
@media (max-width: 768px) {
  .inspectionSectionPhase3Big {
    padding: 0;
  }
  .inspectionProfile .inspectionImg {
    width: 100%;
  }
  .userSection .bannerWarranty {
    padding-top: 0;
  }

  .userSection .userContent h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
  }
  .userSection .userContent label {
    font-size: 14px;
    line-height: 16px;
    padding: 6px 12px;
    align-items: center;
  }
  .userSection .userContent p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .userSection .userContent p {
    margin-bottom: 18px;
    padding-top: 0;
  }

  .bannerInputSection {
    display: none;
  }

  .bannerInputSection2 {
    display: block;
  }

  .inspectionImg {
    width: 358px;
    height: 232px;
    object-fit: cover;
  }

  .report-btn2 {
    background: #eb6d47;
    border-radius: 8px;
    color: #ffffff;
    display: flex;
    padding: 14.5px 17.65px 14.5px 24px;
    gap: 8px;
    border: none;
    cursor: pointer;
    font-family: 'matter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    max-width: 310px;
    width: 100%;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
  }

  .bannerInputSection2 {
    max-width: 100%;
  }

  .inputWrapper2 input[type='text'] {
    max-width: 100%;
  }

  .report-btn2 {
    max-width: 100%;
  }

  .bannerInputSection2 {
    max-width: 100%;
  }

  .inputWrapper2 input[type='text'] {
    max-width: 100%;
  }

  .report-btn2 {
    max-width: 100%;
  }

  .inspection .container {
    padding: 0;
  }

  /* buyback  */
  .securedDesk,
  .headingDesk {
    display: none;
  }
  .buybackWrapper {
    flex-direction: column-reverse;
  }
  .buyback h2 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 2px;
  }
  .buybackImage {
    margin-bottom: 22px;
  }
  .buyback li {
    margin-bottom: 16px;
    padding: 0 10px;
  }
  .buyback li span {
    font-size: 16px;
    line-height: 24px;
  }
  .buyback li img {
    max-width: 22px;
  }
  .buybackList,
  .buybackButtonsDesk {
    display: none;
  }
  .btnDesk {
    display: none;
  }
  .buybackButtons {
    margin-top: 15px;
  }
  .buybackButtons a {
    flex-basis: 50%;
  }
  .buybackContent,
  .buybackImage {
    flex-basis: 100%;
  }
  .buyBackSlider {
    display: initial;
  }

  .bannerSection.homeBannerMobile {
    height: 100%;
    padding-top: 0;
  }
  .bannerHeading label {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 7px;
    margin-top: 0;
    padding: 6px 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .bannerHeading {
    padding-bottom: 62px;
  }
  .bannerHeading h1 {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 12px;
  }
  .bannerHeading p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 22px;
  }
  .securedMobile {
    line-height: 124%;
  }
  .planCard ul {
    flex-direction: column;
  }
  .planCard:last-child li {
    width: fit-content;
  }
  .modalForm {
    padding: 24px;
  }
  .modalContent {
    padding: 24px;
  }
  .modalForm .formField {
    flex-wrap: wrap;
  }
  .range {
    flex-wrap: wrap;
  }
  .saveTime .commonHeading {
    padding-bottom: 30px;
    padding-top: 56px;
  }
  .slick-slide:first-child .calculateContentCard {
    background-size: 82% 48%;
  }
  .slick-slide:last-child .calculateContentCard {
    background-size: 82% 48%;
  }
  .bannerSection {
    padding: 104px 0 20px;
  }
  .bannerContent label {
    font-size: 12px;
    line-height: 14px;
    padding: 6px 14px;
    width: fit-content;
    align-items: center;
    margin-bottom: 24px;
  }
  .bannerContent label img {
    width: 24px;
    height: 24px;
  }
  .bannerContent h1 {
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 0px;
  }
  .bannerReport {
    width: 100%;
    margin-bottom: 23px;
  }
  .bannerContent .cta-button {
    width: auto;
    justify-content: center;
  }
  .bannerList {
    display: flex;
  }
  .bannerWarranty {
    max-width: 100%;
  }
  .reportHeading {
    padding: 16px 11px 22px 14px;
  }
  .getReport {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 22px 24px 21px;
  }
  .getReport input,
  .getReport button.cta-button {
    width: 100%;
  }
  .getReport button.cta-button {
    padding: 16px;
    font-size: 14px;
    line-height: 16px;
  }
  .getReport input {
    background: linear-gradient(
        285.57deg,
        rgba(121, 130, 230, 0.1) 0%,
        rgba(186, 189, 217, 0.1) 100%
      ),
      #ffffff;
    padding: 12px;
    border-radius: 6px;
  }
  .getReport .inputMobile {
    background: linear-gradient(
        285.57deg,
        rgba(121, 130, 230, 0.1) 0%,
        rgba(186, 189, 217, 0.1) 100%
      ),
      #ffffff;
    border-radius: 6px;
    padding-right: 12px;
    margin-bottom: 8px;
  }
  p.carNumberErrorMobile {
    margin-bottom: 24px;
  }
  .carNumberErrorDesk {
    display: none;
  }
  .bannerWarranty p {
    font-size: 12px;
    padding-top: 24px;
  }
  .saving .commonHeading {
    margin-bottom: 62px;
  }
  .calculate .slick-slide {
    max-width: 284px !important;
    margin-right: 16px;
  }
  .calculateContentCard p {
    padding: 22px 22px 0;
    font-size: 16px;
    line-height: 150%;
    min-height: 184px;
  }
  .calculateContentCard img {
    width: 183px;
  }
  .calculate .calculateContentCardBg {
    width: 236px;
  }
  .calculate .cta-button {
    font-size: 16px;
    padding: 12px 24px;
    margin-top: 24px;
    border-radius: 18px;
  }
  .planCard {
    min-height: 416px;
    flex-wrap: wrap;
    padding: 36px 24px;
    overflow: hidden;
  }
  .planCard.card-one {
    top: 4em;
    margin-bottom: -4em;
  }
  .planCard.card-two {
    margin-top: 1em;
    top: 10em;
    margin-bottom: -10em;
  }
  .planCard.card-three {
    margin-top: 7em;
    top: 16em;
    margin-bottom: -16em;
  }
  .planCard h3 {
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 18px;
    padding-top: 0;
  }
  .planCard p {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
    max-width: 250px;
  }
  .planCard ul li {
    font-size: 14px;
    flex-basis: auto;
  }
  .planCard {
    background: linear-gradient(288.97deg, #fefbfa 0%, #ffefeb 100%);
  }
  /* .planCard img{
    max-width: 275px;
  } */
  .contactUsContent a {
    font-size: 16px;
    padding: 32px 24px 40px;
    line-height: 24px;
  }
  .contactUsContent a::after {
    height: 24px;
    top: 28px;
  }
  .contactUs .commonHeading {
    margin-bottom: 32px;
  }
  .warrantyDetailsImage.active {
    display: none;
  }
  .warrantyAccordionAnswer img {
    padding-top: 32px;
  }
  .warrantyAccordion {
    padding-bottom: 16px;
    margin-left: 36px;
  }
  .warrantyAccordionAnswer img {
    display: block;
  }
  .warrantyAccordionQuestion p {
    font-size: 18px;
    line-height: 130%;
  }
  .warrantyAccordionAnswer p {
    font-size: 16px;
    line-height: 24px;
  }
  .warrantyAccordion svg {
    width: 24px;
    top: -1px;
    left: -36px;
  }

  .contactUs {
    padding: 56px 0;
  }
  .serviceContentCard {
    padding: 152px 24px 24px;
  }
  .serviceContentCard h3 {
    font-size: 20px;
    line-height: 26px;
  }
  .serviceContentCard p {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 12px;
  }
  .serviceContentCard a {
    font-size: 16px;
    line-height: 30px;
  }

  .bannerWarranty .slick-slide {
    margin-right: 24px !important;
  }
  .saveTime .commonHeading h2 {
    font-size: 29px;
  }
  .warrantyTableContent table th {
    padding: 12px;
    font-size: 18px;
    line-height: 20px;
  }
  .warrantyTableContent table td {
    padding: 8px 8px 8px 28px;
    font-size: 16px;
    line-height: 24px;
  }
  .warrantyTableContent table td:first-child::before {
    content: url(../../assets/images/home/wrongMobile.svg);
    left: 8px;
    top: 10px;
  }
  .warrantyTableContent table td:last-child::before {
    content: url(../../assets/images/home/rightMobile.svg);
    left: 8px;
    top: 10px;
  }

  /* buyback  */
  /* .securedDesk, .headingDesk {
    display: none;
  }
  .buybackWrapper {
    flex-direction: column-reverse;
  }
  .buyback h2 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 2px;
  }
  .buybackImage {
    margin-bottom: 22px;
  }
  .buybackList li {
    margin-bottom: 16px;
  }
  .buybackList li span {
    font-size: 16px;
    line-height: 24px;
  }
  .buybackList li img {
    max-width: 22px;
  }
  .buybackList {
    padding-bottom: 8px;
  }
  .btnDesk {
    display: none;
  }
  .buybackButtons a {
    flex-basis: 50%;
  }
  .buybackContent,
  .buybackImage {
    flex-basis: 100%;
  } */
}
@media (max-width: 640px) {
  .bannerHeading label {
    margin-bottom: 18px;
  }
  .bannerHeading p {
    margin-bottom: 18px;
  }
  .newContent .bannerSlider {
    padding-bottom: 16px;
  }
  .bannerHeading label {
    margin-bottom: 18px;
  }
  .bannerHeading h1 {
    margin-bottom: 12px;
  }
  .bannerHeading p {
    margin-bottom: 18px;
  }
  .userSection.bannerSection {
    /* height: 100vh; */
    height: 100%;
    padding: 38px 0 20px;
  }
  .planContent {
    padding-bottom: 16em;
  }
  .planCard {
    justify-content: center;
    padding: 24px 16px 0 16px;
    min-height: 400px;
  }
  .planCard.card-one {
    width: 90%;
    top: 3em;
    margin-bottom: -3em;
  }
  .planCard.card-two {
    width: 95%;
    margin-top: 0em;
    top: 9em;
    margin-bottom: -9em;
  }
  .planCard.card-three {
    margin-top: 6em;
    top: 15em;
    margin-bottom: -15em;
  }
  .planCard img {
    position: inherit;
    max-width: 100% !important;
  }

  .planCard ul {
    max-width: 100%;
    flex-direction: inherit;
    row-gap: 8px;
  }
  .planCard ul li {
    font-size: 12px;
  }
  .planCard h3 {
    font-size: 18px;
  }
  .planCard p {
    font-size: 14px;
    line-height: 22px;
  }
  .planCard h3,
  .planCard p {
    max-width: 100%;
    padding-bottom: 12px;
  }
  .saveTimeAccordionAnswer p {
    padding-bottom: 16px;
  }
  .saveTimeAccordionAnswer p:last-child {
    padding-bottom: 32px;
  }
}

@media (max-width: 480px) {
  .userSection.bannerSection .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .cardsContainer {
    padding-right: 12px;
  }
  .bannerSlider .slick-track {
    /* padding-left: 1% !important; */
  }
  .modalForm .formGroup p {
    font-size: 14px;
    line-height: 20px;
  }
  .saveTimeAccordionAnswer h4,
  .saveTimeAccordionAnswer h5 {
    font-size: 18px;
  }
  .modalContent p {
    font-size: 14px;
    line-height: 20px;
    padding: 24px 0;
  }
  .modal .modalMessage p {
    font-size: 14px;
    line-height: 20px;
  }
  .modalContent p span {
    font-size: 26px;
    line-height: 32px;
  }
}
@media (max-width: 374px) {
  .securedDesk,
  .securedMobile {
    font-size: 12px;
  }
  .buybackButtons a {
    font-size: 13px;
  }
  .bannerSlider .slick-track {
    /* padding-left: 8% !important; */
  }
  .commonHeading h2 {
    /* font-size: 24px; */
  }
  .downloadAppStore {
    flex-wrap: wrap;
    justify-content: center;
  }

  .planCard {
    padding: 24px 16px 0;
  }
  .planCard .planMobileImage {
    max-width: 280px;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
  .switch {
    width: 40px;
    height: 28px;
  }
  .slider::before {
    width: 20px;
    height: 20px;
  }
  .modalForm .formGroup div {
    gap: 0;
  }
  .modalForm .formGroup p:first-child,
  .modalForm .formGroup p:last-child {
    padding-right: 4px;
  }
  .saveTimeAccordionQuestion p {
    padding-right: 10px;
  }

  /* buyback  */
  .securedDesk,
  .securedMobile {
    font-size: 12px;
  }
  .buybackButtons a {
    font-size: 13px;
  }
}

@keyframes fill-bar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
/* @keyframes moveDown{
  0%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(0%);
  }
} */
@keyframes moveSlideshow {
  100% {
    transform: translateX(-500px);
  }
}

@keyframes fallDown {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
.mt-50 {
  margin-top: 50px !important;
}
/* @keyframes threeD {
  from {
    transform: translate3d(0);
  }
  to {
    transform: translate3d(0px, 0px, 0px) scale3d(0.900003, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
} */

/* Customer Story Page */

.userAssuredBuyBack {
  padding: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(121, 130, 230, 0.12) 100%
  );
}

.CS-container {
  width: 100%;
  /* height: 92vh; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.CS-titles-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 155px 0;
  gap: 16px;
}
.CS-data-container {
  width: 50%;
  height: 100%;
  padding: 96px 24px;
  gap: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: linear-gradient(107.01deg, #2836d7 0%, #8e93d7 100%);
}
.CS-Shield {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 24px;
  gap: 8px;
  background: linear-gradient(91.18deg, #1bb746 0%, #7bd393 100%);
  border-radius: 48px;
}
.CS-Shield span {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}
.CS-title-Wrapper {
  margin-bottom: 16px;
}
.CS-title-Wrapper h3 {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #eb6d47;
}
.CS-title-Wrapper p {
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #262c6e;
}
.CS-WD-Container li {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: rgba(38, 44, 110, 0.8);
  margin-bottom: 24px;
}
.CS-BTN-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}
.CS-BTN-container .blue-btn {
  padding: 12px 24px;
  background: linear-gradient(107.01deg, #2836d7 0%, #8e93d7 100%);
  border-radius: 8px;
  outline: none;
  color: #ffffff;
  border: 1px solid #2836d7;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.CS-BTN-container .grey-btn {
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #2836d7;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(121, 130, 230, 0.12) 100%
  );
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  background: linear-gradient(107.01deg, #2836d7 0%, #8e93d7 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.CS-data-container h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 160%;
  color: #ffffff;
}

.CS-Slider-card {
  max-width: 530px;
  width: 100%;
  height: fit-content;
  padding: 32px;
  background: linear-gradient(
    132.56deg,
    rgba(255, 255, 255, 0.16) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 24px;
  border: 1px solid #ffffff;
  margin-bottom: 112px;
}
.CS-Slider-card .slick-slider {
  height: 100%;
}
.CS-slider {
  width: 100%;
  height: 70%;
  padding: 24px;
}
.CS-slider div {
  width: 100%;
  height: 70%;
}
.slider-comment {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 180%;
  color: #ffffff;
}
.slider-comment-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  color: #ffffff;
}
.slider-carBrand {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
}
.slider-Avatar {
  width: 60px;
  height: 58px;
  border-radius: 50%;
  background-color: #ffffff;
}
.slider-avatar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 40px;
}
.CS-Slider-card .slick-dots li button:before {
  opacity: 0.75;
  color: #ffffff;
  line-height: 14px;
}
.CS-Slider-card .slick-dots {
  bottom: -160px;
}
.CS-Slider-card .slick-dots li.slick-active button:before {
  opacity: 1;
  color: transparent;
  height: 1%;
  background: #ffff;
  border-radius: 30px;
  padding: 7px;
  width: 15px;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 40px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 460px) {
  .inspectionProfile {
    width: 100%;
  }
  .inspectionProfile .inspectionImg {
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .CS-data-container {
    margin-right: -120px;
    max-width: 720px;
    width: 100%;
  }

  .CS-titles-container {
    flex-basis: 47%;
  }
}

@media (max-width: 1058px) {
  .CS-BTN-container .grey-btn {
    padding: 12px 12px;
  }
}
@media (max-width: 992px) {
  .userBuyBackCsBtn.CS-BTN-container .blue-btn,
  .userBuyBackCsBtn.CS-BTN-container .grey-btn {
    width: 100%;
    white-space: nowrap;
  }
  .CS-title-Wrapper {
    margin-bottom: 12px;
  }
  .userAssuredBuyBack .container {
    padding: 0;
  }
  .CS-data-container .slick-dots {
    bottom: -42px;
  }
  .CS-Shield {
    padding: 8px 12px;
    justify-content: center;
  }
  .CS-Shield img {
    width: 16px;
    height: 16px;
  }
  .CS-container {
    flex-direction: column;
    height: fit-content;
    gap: 40px 20px;
  }
  .CS-titles-container {
    width: 100%;
    padding: 56px 16px 0;
    gap: 8px;
  }
  .CS-data-container {
    width: 100%;
    gap: 24px;
  }
  .CS-Shield span {
    font-size: 13px;
    line-height: 120%;
  }
  .CS-title-Wrapper p {
    font-size: 28px;
    line-height: 36px;
  }
  .CS-title-Wrapper h3 {
    font-size: 28px;
    line-height: 36px;
  }
  .CS-WD-Container li {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 16px;
  }
  .CS-WD-Container li img {
    width: 16px;
    height: 16px;
  }
  .CS-BTN-container a {
    flex-basis: 50%;
  }
  .CS-BTN-container .blue-btn {
    width: 50%;
    padding: 12px 16px;
    gap: 4px;
    text-align: center;
    font-size: 12px;
  }
  .CS-BTN-container .grey-btn {
    width: 50%;
    padding: 12px 16px;
    gap: 4px;
    text-align: center;
    font-size: 12px;
  }
  .CS-Slider-card {
    padding: 0px;
    background: linear-gradient(
      132.56deg,
      rgba(255, 255, 255, 0.16) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 24px;
    margin-bottom: 32px;
    border: 1px solid #ffffff;
  }
  .CS-data-container {
    padding: 24px;
  }
  .slider-comment {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
  }
  .slider-comment-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .slider-Avatar {
    width: 40px;
    height: 40px;
  }
  .CS-data-container h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
  }
}

.numberBox {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  min-width: 344px;
  min-height: 72px;
  align-items: center;
  margin-top: 15px;
}

.numberBox > div {
  height: 72px;
  width: 64px;
  text-align: center;
  font-weight: 700;
  font-size: 42px;
  line-height: 36px;
  border: 1px solid rgba(228, 230, 232, 1);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #262c6c;
  border-radius: 10px;
}

@media (max-width: 769px) {
  .CS-Slider-card {
    padding: 0px;
    background: linear-gradient(
      132.56deg,
      rgba(255, 255, 255, 0.16) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .userBannerHeading span:nth-child(2) {
    font-size: 34px;
    display: inline-block;
    margin-top: 8px;
    font-weight: 500;
  }
}

@media (max-width: 425px) {
  .viewCarBtn {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding-right: 22px; */
    justify-content: center;
    align-items: flex-end;
  }

  .numberBox {
    width: 100%;
    padding-left: 20px;
  }

  .viewCarBtn > div {
    /* width: 120%; */
  }

  .viewCarBtn > a {
    /* align-self: flex-end; */
    /* margin-left: auto; */
    margin-right: 20px;
  }
}

@media (max-width: 380px) {
  .userSection {
    padding: 20px 0px 11px 0px;
  }

  .numberBox div {
    width: 54px;
    height: 64px;
    font-size: 38px;
  }
}
