.benifits-container {
}
.benifits-note {
  color: #0c286a !important;
  font-size: 22px;
  /* font-family: matterSemi; */
}
.benifits-wrap {
  z-index: 2;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  border-radius: 48px;
  justify-content: center;
  width: 100%;
  padding: 0 36px 0 0;
  /* margin: 40px; */
  display: flex;
  position: sticky;
  top: 150px;
  flex-wrap: 'wrap';
  justify-content: 'flex-start';
  align-items: center;
}
.benifits-note-bar {
  width: 90%;
  background-color: #ff7858;
  box-shadow: 0px 2px 11px 0px #ff7858 !important;
  border-radius: 10px;
  margin-top: 30px;
}
.benifits-note-bar p {
  font-family: 'matterMedium';
  color: #fff;
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
}

.benifits-left-section {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 500px;
  /* 370px;*/
  display: flex;
}

.benifits-left-section h2 {
  font-size: 50px;
  color: white;
  /* font-weight: 400; */
  line-height: 52px;
  margin-bottom: 24px !important;
}

.benifits-left-section p {
  color: white;
  padding-top: 0px !important;
  font-size: 17px;
}

.benifits-card p {
  color: #0c286a;
  font-family: 'matterMedium';
  font-size: 18px;
  padding-top: 5px;
}
.card-highlet {
  color: #0c286a;
  font-size: 24px;
  /* font-family: 'matterMedium'; */
}
.benifits-left-content {
  grid-column-gap: 64px;
  flex-direction: column;
  justify-content: space-between;
}
.benifits-left-content h2 {
  color: #0c286a;
}

/* .benifits-right-section {
  flex: 1 0 1;
} */

.benifits-cards-wrap {
  /* max-width: 690px; */
  /* margin-left: auto; */
  /* padding: 10px; */
}

.tab-active {
  /* grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid; */
  display: flex;
}

.tab-active-sub {
  display: grid;
  align-items: start;
  align-content: center;
}

.benifits-card {
  display: flex;
  padding: 1.25rem;
  border-radius: 8px;
  /* box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4); */
  margin: 0.75rem;
  width: 400px;
  /* margin-bottom: 5rem; */
  white-space: normal;
  outline: 0;
  background-color: #dceeff;
  flex-direction: column;
  justify-content: space-between;
}

.benifits-card-sub {
  border: 1px solid #fff;
  border-radius: 32px;
  max-width: 100%;
  min-height: 255px;
  padding: 40px 0 0 36px;
  text-decoration: none;
  display: flex;
  position: relative;
}
.benifits-chip {
  background-color: #59ffdc !important;
  opacity: 1 !important;
  color: #0c286a !important;
  font-size: 16px !important;
  font-family: 'matterMedium';
  padding: 10px !important;
}
.chips-container {
  margin-top: 25px;
}

.btn-insurance-conatiner {
  display: flex;
  gap: 10px;
}

.insurance-card-conatiner {
  display: flex;
}
/* .benifits-green {
    background-color: #cfebdd;
    justify-content: space-between;
}

.benifits-purple {
    background-color: #d9e7fd;
    justify-content: space-between;
}

.benifits-yellow {
    background-color: #fdf0cc;
    justify-content: space-between;
}

.benifits-pink {
    background-color: #f8d9d7;
    justify-content: space-between;
} */

.benifits-card-content {
  align-items: center;
  display: flex;
}

.benifits-card-content-base {
  padding-top: 10px;
}

.benifits-card-content-footer-btn {
  margin-top: 1.5rem;
}

.benifits-card-content-footer-btn p {
  /* font-family: 'matterThin' !important; */
  color: #0c286a !important;
  line-height: 20px;
  margin-top: 6px;
}
/* .benifits-card-content-footer-btn span {
  font-size: 18px;
   color: #ff7858; 
} */

.benifits-card-content .product-item-heading {
  width: 100%;
  /* margin-bottom: 20px; */
  line-height: 30px;
  position: relative;
  font-size: 22px;
  font-family: 'matter';
  color: #0c286a;
  display: flex;
  align-items: center;
  gap: 10px;
}

.benifits-card-content .product-item-desc {
  max-width: 160px;
  margin-bottom: 0;
  font-weight: 300;
  line-height: 20px;
}

.benifits-card-content-full {
  /* max-width: 322px; */
  display: flex;
  align-items: center;
}

.benifits-full {
  display: flex;
  width: 50% !important;
  flex-direction: column;
  justify-content: space-between;
}

.benifits-card-content-full .product-item-heading {
  width: 100%;
  font-size: 22px;
  line-height: 30px;
  /* padding-left: 10px; */
  color: #0c286a;
  font-family: 'matter';
}

.benifits-card-content-full .product-item-desc {
  font-weight: 300;
  line-height: 20px;
}

.product-item-img {
  animation: translate-image 1.2s infinite alternate ease-in-out;
  animation: translate-image 1.2s infinite alternate ease-in-out;
  width: 100px;
  height: 100px;
}

/* .css-sghohy-MuiButtonBase-root-MuiButton-root .btn-insurance {
    border-radius: 48px !important;
    border: 1px solid #0A65C2;
    justify-content: center;
    align-items: center;
    padding: 20px;
    display: flex;
    color: white;
    font-size: 30px;
    margin-bottom: 20px;
    width: 100%;
} */

.btn-insurance {
  border-radius: 15px !important;
  /* box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4) !important; */
  align-items: center !important;
  padding: 15px !important;
  display: flex !important;
  font-size: 22px !important;
  margin-bottom: 20px !important;
  width: 90% !important;
  text-transform: none !important;
  font-family: 'matterSemiBold' !important;
  background-color: white !important;
  color: #0c286a !important;
  justify-content: space-between !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: 1px solid #0c286a !important;
  box-shadow: none !important;
}

.btn-insurance-mobile {
  border-radius: 5px !important;
  /* box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4) !important; */
  align-items: center !important;
  padding: 5px !important;
  display: flex !important;
  font-size: 25px !important;
  margin-bottom: 20px !important;
  /* width: 90% !important; */
  text-transform: none !important;
  font-family: 'matterSemiBold' !important;
  background-color: white !important;
  color: #0c286a !important;
  /* justify-content: space-between !important; */
  /* padding-left: px !important;
  padding-right: 25px !important; */
  border: 1px solid #0c286a !important;
  box-shadow: none !important;
}
/* .btn-insurance:hover {
    background-color: #0A65C2 !important;
    border: 1px solid #0A65C2 !important;
    color:white !important;
} */

.insurance-active {
  background-color: #dceeff !important;
  border: 1px solid #dceeff !important;
  color: #0c286a !important;
}
.insurance-active-green {
  background-color: #ccedd0 !important;
  border: 1px solid #ccedd0 !important;
  color: #0c286a !important;
}
.insurance-active-orange {
  background-color: #fff2e4 !important;
  border: 1px solid #fff2e4 !important;
  color: #0c286a !important;
}
.css-w66kx-MuiChip-root {
  font-family: 'matter' !important;
  background-color: #59ffdc !important;
  color: #0c286a !important;
}
/* .css-1q5nkkt-MuiButtonBase-root-MuiChip-root.Mui-disabled {
  font-family: "matter" !important;
  opacity: 1 !important;
  background-color: #0a65c2 !important;
  color: white !important;
} */

.btn-insurance-container {
  display: none;
}

.product-item-heading-left {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #0c286a;
  padding: 5px;
  /* width: 110px; */
}
@media (max-width: 992px) {
  .css-1xn2o4r-MuiStack-root {
    gap: 8px;
    margin: 10px;
  }
  .benifits-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .benifits-left-section h2 {
    padding-left: 15px;
    padding-right: 10px;
    font-size: 34px;
    line-height: 45px !important;
    font-family: 'matterSemiBold';
  }
  .benifits-card-wrap {
    margin-bottom: 50px;
  }
  .btn-insurance-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
    margin: 10px;
  }
  .btn-insurance-conatiner-left {
    display: none;
  }
  .btn-insurance-mobile {
    /* width: 97% !important;
    border: 1px solid #0c286a !important;
     */
    width: auto !important;
    font-size: 14px !important;
    border: 1px solid #0c286a !important;
  }

  .btn-insurance {
    border-radius: 5px !important;
    width: 97% !important;
    border: 1px solid #0c286a !important;
  }
  .benifits-card {
    width: 100%;
    flex-wrap: wrap;
    padding: 24px;
    border: none !important;
    margin: 0;
    margin-bottom: 10px;
  }

  .benifits-full {
    width: 97% !important;
  }

  .benifits-right-section {
    padding-left: 10px;
  }
  .benifits-card-content-base p {
    padding-left: 0px !important;
  }
  p {
    padding-left: 10px !important;
  }
}

@media (max-width: 600px) {
  .css-1xn2o4r-MuiStack-root {
    gap: 8px;
    margin: 10px;
  }

  .tab-active {
    /* grid-template-columns: 1fr !important; */
  }
  .benifits-left-section h2 {
    padding-left: 15px;
    padding-right: 10px;
    font-size: 34px;
    line-height: 45px !important;
    font-family: 'matterSemiBold';
  }
  .benifits-right-section {
    padding-left: 10px;
  }
  .benifits-card-content-base p {
    padding-left: 0px !important;
  }
  p {
    padding-left: 10px !important;
  }

  .btn-insurance-mobile {
    /* width: 97% !important;
    border: 1px solid #0c286a !important;
     */
    width: auto !important;
    font-size: 14px !important;
    border: 1px solid #0c286a !important;
  }

  .btn-insurance {
    border-radius: 5px !important;
    width: 97% !important;
    border: 1px solid #0c286a !important;
  }

  .benifits-card {
    margin: 0;
    margin-bottom: 10px;
  }
  .benifits-full {
    width: 97% !important;
  }
  .benifits-note {
    line-height: 30px;
    /* padding-left: 15px !important; */
  }
}

@media (max-width: 768px) {
  .product-item-heading-left p {
    padding-left: 0px !important;
  }
  .insurance-card-conatiner {
    flex-direction: column;
  }
  .product-item-heading-left {
    /* width: 145px; */
  }
  .benifits-left-section h2 {
    padding-left: 15px;
    padding-right: 10px;
    font-size: 34px;
    line-height: 45px !important;
    font-family: 'matterSemiBold';
  }
  .benifits-cards-wrap {
    padding: 10px;
  }
  .benifits-cards-wrap .tab-active {
    display: flex;
  }

  .btn-insurance-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
    margin: 10px;
  }
  .btn-insurance-conatiner {
    display: none;
  }
  .benifits-full {
    width: 97% !important;
  }
  .btn-insurance-mobile {
    /* width: 97% !important;
    border: 1px solid #0c286a !important;
     */
    width: auto !important;
    font-size: 14px !important;
    border: 1px solid #0c286a !important;
  }

  .btn-insurance {
    border-radius: 5px !important;
    width: 90% !important;
    border: 1px solid #0c286a !important;
  }
  .tab-active-sub {
    display: flex;
    flex-wrap: wrap;
  }
  .tab-active {
    display: flex;
    flex-wrap: wrap;
  }

  .benifits-card h3 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 23px;
  }

  .benifits-card {
    margin: 0;
    margin-bottom: 10px;
  }
  .benifits-card p {
    font-size: 16px;
    line-height: 24px;
  }
  .benifits-right-section {
    /* padding-left: 10px; */
  }
  .benifits-card-content-base p {
    padding-left: 0px !important;
  }
  p {
    padding-left: 10px !important;
  }
  .benifits-note {
    /* line-height: 30px; */
    font-family: 'matter';
    padding-right: 10px;
    font-size: 20px;
    /* padding-left: 15px !important; */
  }
  .h2MobileSize {
    padding-left: 10px;
    font-size: 38px;
    /* line-height: 35px !important; */
  }
  .btn-insurancem1 {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
}
