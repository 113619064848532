.btn-modal-close {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
}
.modal-text-mobile {
  margin-bottom: 1rem;
}
.modal-box-css {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 30%;
  transform: translate(-50%, -50%);
  background-color: ghostWhite;
  /* border: 2px solid #fff !important; */
  border-radius: 5px;
  padding: 1rem;
}

@media (max-width: 768px) {
  .modal-box-css {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    background-color: ghostWhite;
    border-radius: 5px;
    /* border: 2px solid #fff !important; */
    padding: 1rem;
  }
  .modal-text-mobile {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  .modal-box-book-inspection {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    background-color: ghostWhite;
    border-radius: 5px;
    /* border: 2px solid #fff !important; */
    padding: 1rem;
    overflow-y: auto;
  }
}
@media (max-width: 480px) {
}
@media (max-width: 875px) {
}
@media (max-width: 776px) {
}
