.footer .footer__core {
  margin-bottom: 6rem;
}

.grid {
  clear: both;
  margin: 0;
  margin-left: -28px;
  padding: 0;
  position: relative;
}

.large-width-5 {
  width: 41.6666666667% !important;
}
.grid-item {
  padding-left: 30px;
}
.large-width-7 {
  width: 58.3333333333% !important;
}
.footer .footer__core .footer__core__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.medium-width-4 {
  width: 33.3333333333% !important;
}
