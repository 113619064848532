/* brand Section */
.brand {
  padding: 60px 0 40px;
  background-color: white;
}

.brand .brandDetails {
  display: flex;
  /* justify-content: center; */
}

.brandDetails .brandLogoSection {
  width: 35%;
  display: block;
}

.brand .commonHeading {
  /* max-width: 700px; */
  margin-bottom: 40px;
}
.commonHeading h2 {
}

.brandCards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1%;
  row-gap: 10px;
  justify-content: space-between;
}

.brandCardsNew .card {
  min-height: 250px;
  border-radius: 15px !important;
  box-shadow: 0px 10px 20px 0px rgba(164, 172, 179, 0.4) !important;
  padding: 1.25rem;
}
.brandCardsNew .card1 {
  border-radius: 15px !important;
  padding: 2.25rem;
  /* border: 2px solid #0c286a; */
}

.card p {
}
.brand-cards-left {
  width: 30%;
}
.brand-cards-center {
  width: 35%;
  margin: 10px;
}
@media (max-width: 1330px) {
  .downloadScanImage {
    right: 0;
  }
}

@media (max-width: 992px) {
  .brand {
    padding: 10px;
  }

  .brandCards {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .brand-cards-left {
    width: 100%;
  }
  .brand-cards-center {
    width: 100%;
    margin: 5px;
  }
  .brand-cards-left p {
    padding-left: 0px !important;
  }
  .brand-cards-center p {
    padding-left: 0px !important;
  }
  .brandCards .card {
    padding: 24px;
    width: 100%;
    border: none !important;
  }

  .card {
    border: none !important;
  }
}

@media (max-width: 768px) {
  .brand {
    padding: 30px 10px;
  }

  .brandCards .card h3 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 23px;
  }

  .brandCards .card p {
    font-size: 16px;
    line-height: 24px;
  }
  .commonHeading h2 {
    padding-left: 10px;
  }
  .grade-description {
    font-family: 'matterThin' !important;
    line-height: 25px;
  }
  .brand-cards-left p {
    padding-left: 0px !important;
  }
  .brand-cards-center p {
    padding-left: 0px !important;
  }
}
