@font-face {
  font-family: 'matter';
  /*Can be any text*/
  src: local('matter'), url('../../fonts/Matter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'matterBold';
  /*Can be any text*/
  src: local('matterBold'),
    url('../../fonts/Matter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'matterSemiBold';
  /*Can be any text*/
  src: local('matterSemiBold'),
    url('../../fonts/Matter-SemiBold.ttf') format('truetype');
}

.sample-report-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(229, 229, 229);
  min-height: 100vh;
}

.sample-report-header {
  display: flex;
  flex-direction: column;
  padding: 60px 70px 0px 180px;
  min-height: 70vh;
  background-color: #003cc5;
  /* rgb(33, 35, 56); */
}

.sample-report-content {
  display: flex;
  flex-direction: column;
}

.sample-report-refference {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0px;
  text-align: right;
  color: white;
}
.image-span-1 {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100%;
}

.image-span-1 .image-span-2 {
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  width: 250px;
  max-width: 100%;
}

.sample-report-description {
  display: flex;
  flex-direction: column;
  color: white;
  font-style: normal;
  margin-bottom: 220px;
}

.sample-report-description p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: white;
  margin: 0px;
}

.sample-report-description h1 {
  font-family: 'matterSemiBold';
  font-weight: 700;
  font-size: 55px;
  line-height: 64px;
  margin: 16px 0px 32px;
  color: white;
}

.sample-report-description span {
  font-family: 'matter';
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: white;
}

.sample-report-body {
  width: 100%;
}
.sample-report-body-container {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  margin: -204px 70px 40px;
  background-color: white;
  padding: 100px 110px;
}
.sample-report-body-header {
  display: flex;
  flex-direction: column;
}

.sample-report-body-header h1 {
  font-family: 'matterSemiBold';
  font-size: 32px;
  line-height: 48px;
  margin: 0px 0px 80px;
  font-weight: 700;
  color: rgb(33, 35, 56);
}

.sample-report-vechileinfo {
  display: flex;
  flex-direction: row;
  margin: 80px 0px;
}

.vechileinfo-left {
  display: block;
  width: 100%;
  height: 10%;
  max-width: 470px;
  max-height: 316px;
  margin-right: 80px;
  position: relative;
  cursor: pointer;
}

.vechileinfo-right {
  display: flex;
  flex-direction: column;
}

.vechileinfo-left-span-image {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
}
.vechileinfo-left-span-image .vechileinfo-left-span-image-1 {
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  padding-top: 67.23404255319149%;
}

.inspection-photos {
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image-count-capsule {
  position: absolute;
  background-color: white;
  padding: 12px 20px;
  bottom: 10px;
  left: 10px;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgb(33, 35, 56);
}

.vechileinfo-container {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.vechileinfo-container .vechileInfo-label {
  min-width: 200px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgb(100, 101, 116);
}

.vechileinfo-container .vechileInfo-value {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgb(33, 35, 56);
}

.vechile-rating-guide {
  display: flex;
  flex-direction: column;
  margin: 80px 20px;
  -webkit-box-align: flex-start;
  align-items: flex-start;
}

.vechile-rating-guide h1 {
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: rgb(33, 35, 56);
  margin: 0px 0px 24px;
}

.vechile-rating-guide-container {
  display: flex;
  flex-direction: column;
}

.vechile-rating-guide-container .vechile-rating-guide-content {
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin-top: 24px;
}

.vechile-rating-label {
  min-width: 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgb(100, 101, 116);
}
.vechile-rating-value {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgb(100, 101, 116);
}

.sample-report-mechanic-notes {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 40px;
  margin: 80px 0px 0px;
}

.mechanic-notes-left-quotes {
  display: flex;
}

.left-quotes {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100%;
}

.left-quotes .left-quotes-1 {
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.left-quote-css {
  display: block;
  max-width: 100%;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
}

.mechanic-notes-content {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.mechanic-notes-content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  /* line-height: 32px; */
  margin: 0px 0px 64px;
  color: rgb(33, 35, 56);
}

.mechanic-notes-content p {
  max-width: 594px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin: 0px 0px 32px;
  text-align: center;
  color: rgb(33, 35, 56);
}

.mechanic-notes-right-quotes {
  display: flex;
}

.right-quotes {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100%;
}

.right-quotes .right-quotes-1 {
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.right-quote-css {
  display: block;
  max-width: 100%;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
}

.inspection-report-container {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 80px auto;
}

.report-type-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.report-type-section h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
  color: rgb(33, 35, 56);
}

.report-value-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgb(100, 101, 116);
}

.report-value-container span {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: rgb(112, 191, 68);
}

.inspection-report-details-container {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 0;
  margin: 55px 0px 0px;
}

.inspection-report-details-header {
  position: sticky;
  top: 0px;
  padding-top: 25px;
  background-color: rgb(255, 255, 255);
  z-index: 1;
}
.inspection-report-details-header-container {
  display: flex;
  flex-flow: column wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
}

.inspection-report-details-menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  overflow: auto hidden;
}

.inspection-report-details-menu > :not(:last-child) {
  margin-right: 20px;
}

.inspection-report-details-menu .inspection-menu-items {
  font-family: 'matterSemiBold';
  z-index: 1;
  padding-bottom: 6px;
  margin-bottom: 20px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: rgb(100, 101, 116);
}
.inspection-report-details-menu .inspection-menu-items-Active {
  z-index: 1;
  border-bottom: 3px solid #70bf44;
  padding-bottom: 6px;
  margin-bottom: 20px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: rgb(100, 101, 116);
}
.inspection-report-details-menu .inspection-menu-items span {
  font-family: 'matterSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgb(144, 145, 155);
  margin-bottom: 80px;
}

.inspection-report-details-btn-cnt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn-view-photos {
  background-color: red !important;
}

.inspection-report-details-btn-cnt .MuiButton-root {
  background: rgb(112, 191, 68) !important;
  border-radius: 8px;
  border-color: rgb(112, 191, 68) !important;
  border-style: solid !important;
  cursor: pointer !important;
  display: flex !important;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  padding: 20px 24px !important;
  color: white !important;
  opacity: 1;
  height: 40px !important;
}

.inspection-report-menu-container {
  scroll-margin-top: 200px;
  padding-top: 50px;
}
.inspection-report-menu-container .fadeIn {
  animation-duration: 0.6s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

.inspection-report-menu-container .menu-item-header {
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  color: rgb(144, 145, 155);
}

.menu-item-header strong {
  font-weight: 700;
}
.menu-item-header span {
  font-weight: 700;
  color: rgb(112, 191, 68);
}

.menu-item-description-contents {
  width: 100%;
  padding: 80px 0px;
  border-bottom: 1px solid rgb(211, 211, 215);
}

.sample-report-tandc p {
  font-family: 'matterSemiBold';
  font-size: 16px;
  line-height: 24px;
  color: rgb(33, 35, 56);
}

.sample-report-tandc span {
  font-size: 15px;
  color: rgb(112, 191, 68);
  cursor: pointer;
}

.sample-report-tandc .arrow-css {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100%;
}
.sample-report-tandc-desc {
  width: 100%;
}

.sample-report-tandc-desc h1 {
  color: rgb(33, 35, 56);
  font-size: 24px;
  line-height: 40px;
  margin: 20px 0px;
}

.sample-report-tandc-desc .tandc-header {
  font-family: 'matterBold';
}
.sample-report-tandc-desc .tandc-desc {
  font-family: 'matterMedium';
}
