.faqSection {
  padding: 60px 0 40px;
}

.faq-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 10%;
  row-gap: 24px;
  align-content: center;
}

.faq-cards-container .faq-card {
  /* padding: 10px; */
  border-radius: 15px;
  border: 1px solid transparent;
  width: 25%;
  max-width: 90%;
}

.faq-card p {
  font-family: 'matter';
  font-size: 22px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical;
}
.faq-container-wrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.faq-container-wrap-left {
  width: 50%;
}
/* .faq-cards .card {
    padding: 1.25rem;
} */
.MuiPaper-root-MuiAccordion-root {
  background-color: red !important;
}

@media (max-width: 768px) {
  .faqSection {
    padding: 20px 20px 40px;
  }
  .faq-container-wrap {
    flex-direction: column;
    gap: 10px;
  }

  .faq-container-wrap-left {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .faq-container-wrap {
    flex-direction: column;
    gap: 10px;
  }

  .faq-container-wrap-left {
    width: 100%;
  }
}
@media (max-width: 875px) {
  .faq-container-wrap {
    flex-direction: column;
    gap: 10px;
  }

  .faq-container-wrap-left {
    width: 100%;
  }
}
@media (max-width: 776px) {
  .faq-container-wrap {
    flex-direction: column;
    gap: 10px;
  }

  .faq-container-wrap-left {
    width: 100%;
  }
}
