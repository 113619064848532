.buynow-container {
  display: flex;
  justify-content: space-between;
  padding: 40px;
}

.stepper-container-buyNow {
  display: flex;
  align-items: center;
  width: 50%;
}

.stepper-labels {
  display: flex;
  justify-content: center;
  margin-top: 6%;
}

.stepper-labels h6 {
  color: #003cc4;
}

.buyNow-header {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.buyNow-header p {
  font-family: 'matter';
  padding-top: 5px;
  color: black;
}
.city-container {
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  margin-bottom: 25px;
}

.city-container-mobile {
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 5px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  margin-bottom: 25px;
}
.city-container-mobile p {
  font-family: 'matterMedium';
  font-size: 16px;
  padding: 0px !important;
  /* paddingLeft: '0px !important', */
  /* textAlign: 'center', */
}
.city-btn-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 10px;
  margin-top: 20px;
}
.city-btn-paynow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.buyNow-content .buyNow-header h6 {
  color: #003cc4;
}
.buyNow-content {
  display: flex;
  justify-content: space-evenly;
}
.buyNow-content h6 {
  color: black;
}
.buyNow-content p {
  color: black;
  padding-top: 3px;
}
.btn-buyNow {
  border-color: white !important;
  background-color: inherit !important;
  /* color: orange !important; */
  opacity: 2;
  height: fit-content;
  text-transform: none !important;
  padding: 0px 9px !important;
  border-width: medium !important;
  border-radius: 15px !important;
}
.btn-buyNow p {
  color: white;
}
.btn-buyNow-active p {
  color: #0c286a;
}
.btn-price-css {
  line-height: 20px;
  padding-top: 0px;
  padding-bottom: 6px;
  color: rgb(38, 44, 110);
}
.btn-buyNow-active {
  font-family: 'matterMedium' !important;
  border-color: #e0fc00 !important;
  background-color: #e0fc00 !important;
  color: #0c286a !important;
  height: fit-content;
  text-transform: none !important;
  border-width: medium !important;
  padding: 0px 9px !important;
  border-radius: 15px !important;
}
.btn-buynow-inline {
  display: flex;
  /* line-height: 20px; */
  flex-direction: column;
  align-items: flex-start;
}
.buyNow-paper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  /* padding: 20px; */
  /* padding-left: 40px; */
  padding-bottom: 0px;
  align-items: center;
  /* box-shadow: 0px 10px 20px 0px rgba(164, 172, 179) !important; */
}
.buyNow-paper p {
  /* color: white; */
}

.buyNow-Container-left {
  display: flex;
  /* width: 30%; */
  justify-content: flex-start;
  gap: 10px;
}

.buyNow-otp {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  height: 100%;
  /* padding: 20px; */
  padding-bottom: 0px;
  /* box-shadow: 0px 10px 20px 0px rgba(164, 172, 179) !important; */
}
.buyNow-otp input {
  /* background: #f2f2fc; */
  width: 50px !important;
  height: 50px !important;
  margin: 10px !important;
  border-radius: 4px;
  border: 2px solid #003cc4;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
}
.buyNow-otp p {
  /* color: white !important; */
}
.buyNow-paper div {
  /* padding-bottom: 12px; */
}

.buyNow-invoice {
  margin-top: 25px;
  display: flex;
  gap: 0px;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-evenly;
}

.buynow-tablesec {
  padding: 20px 24px 24px;
  background: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  max-width: 861px;
  width: 100%;
  margin: auto;
}
.bunow-table-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-basis: 90%;
}

.bunow-table-content .div-invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 10px; */
}

.bunow-table-content .div-promocode {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.bunow-table-content p {
  font-weight: 500;
  font-size: 16px;
  color: white;
  padding-top: 0px;
  padding-bottom: 10px;
}
.bunow-table-content span {
  font-weight: 600;
  font-size: 14px;
  color: black;
}
.bunow-table-content h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 171%;
  color: white;
}
.bunow-table-content h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 123%;
  color: white;
}
.seperator {
  width: 100%;
  height: 1px;
  border: 1px solid #ddddf4;
}
.btn-modal-paynow {
  margin-top: 20px;
}
.bunow-table-content button {
  font-family: 'matterSemibold';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0c286a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 14px;
  background: #e0fc00;
  border-radius: 30px;
  text-transform: uppercase;
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 8px;
}
.doneIconModal {
  color: black;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-right: 5px;
  vertical-align: text-bottom;
  width: 20px;
  height: 20px;
}
.newIconModal {
  color: #eb6d47;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-right: 5px;
  vertical-align: text-bottom;
}

.modal_note {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.modal_note p {
  font-family: 'matter';
  font-size: 14px;
}

.txt-promoCoe {
  color: black !important;
  width: 100%;
}
/* .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: white !important;
  font-family: "matter" !important;
} */

/* .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  font-family: "matter" !important;
  color: white !important;
} */

.close-proceed-css {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.MuiTextField-root {
  color: white !important;
}

.otp-input-container div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0px;
}

.otp-input input {
  background: #f2f2fc;
  width: 48px !important;
  height: 48px !important;
  margin: 10px !important;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
}

.MuiAutocomplete-listbox {
  display: flex !important;
  flex-direction: column;
  color: black;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  /* width: 100%; */
  padding: 0px !important;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
  overflow: visible !important;
}

.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  color: white !important;
  /* height: 10px !important; */
  padding: 16.5px 14px !important;
  font-family: 'matter';
}
.MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
  font-family: 'matterBold' !important;
}

.MuiFormControl-root-MuiTextField-root {
  width: 70% !important;
}
.MuiInputBase-input-MuiOutlinedInput-input {
  color: white !important;
  font-family: 'matterBold' !important;
}

.MuiFormLabel-root {
  font-family: 'matter' !important;
  /* color: red !important; */
}
.MuiButton-root.Mui-disabled {
  color: #ddddf4 !important;
  background-color: rgb(138, 154, 168) !important;
}

@media (max-width: 768px) {
  .buyNow-Container-left hr {
    display: none;
  }
  .buynow-container {
    padding: 0px;
    margin-top: 10px;
  }
  .city-btn-container {
    gap: 10px;
    flex-direction: column;
  }
  .mobile-hide {
    display: none;
  }

  .buyNow-otp input {
    /* background: #f2f2fc; */
    width: 40px !important;
    height: 40px !important;
    margin: 0px !important;
    border-radius: 4px;
    border: 2px solid #003cc4;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
  }
}
