.querySection {
  /* padding: 5px; */
  background-color: #f8f8f8;
  padding-bottom: 25px;
}

.query-cards-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3%;
  row-gap: 24px;
  align-content: center;
  justify-content: space-between;
}

.query-cards-container .query-card {
  border-radius: 15px;
  padding: 2.25rem;
  border: 2px solid #0c286a;
  width: 48%;
  max-width: 100%;
}

.query-card-header {
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  align-items: center;
}
.query-card-header h5 {
  font-family: 'matterBold';
  color: #0c286a;
  font-size: 30px;
}

.commonHeading p {
  font-family: 'matter';
}

.query-card p {
  font-family: 'matter';
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-top: 2px;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical;
}

.query-card h6 {
  font-size: 20px !important;
}
.query-card-image {
  height: 50px;
  width: 50px;
  border-radius: 10px;
}

/* Updated code */

.how-it-works-card-wrap {
  display: flex;
  gap: 5%;
  /* grid-template-columns: 1fr 1fr 1fr; */
}

.how-it-works-card {
  padding: 40px;
  padding-top: 15px;
  border: 2px solid darkgray;
  border-radius: 5px;
}

.how-it-works-card-body p {
  /* padding-top: 0px; */
  color: black;
  /* line-height: 5px; */
}
.how-it-works-card-head {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.how-it-works-card-head h5 {
  color: rgb(9, 67, 198);
  font-size: 26px;
  font-family: 'matterThin';
}

.query-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.query-header h3 {
  font-size: 45px;
}
.how-it-works-img {
  width: 120px;
}

.advisor-container {
  margin-top: 2em;
  display: flex;
  align-items: center;
  gap: 1em;
}

.advisor-container p {
  font-size: 24px;
}
@media (max-width: 768px) {
  .query-cards-container {
    flex-direction: column;
  }
  .querySection {
    padding: 10px 15px 25px 10px;
  }
  .query-card-header {
    gap: 10px;
  }
  .query-header {
    flex-direction: column-reverse;
  }
  .query-header h3 {
    font-size: 30px;
    font-family: 'matterThin';
    padding-left: 10px;
  }

  .query-header p {
    font-family: 'matterThin' !important;
  }
  .commonHeading p {
    font-family: 'matterMedium';
    line-height: 26px;
  }
  .query-card-image {
    height: 45px;
    width: 45px;
    border-radius: 10px;
  }
  .query-card-header h5 {
    font-family: 'matterSemiBold';
    font-size: 25px;
  }
  .how-it-works-card-body p {
    font-size: 16px;
    line-height: 25px;
    padding-top: 0px;
  }
  .query-cards-container .query-card {
    border-radius: 10px;
    padding: 1rem;
    border: 2px solid #0c286a;
    width: 100%;
    max-width: 100%;
  }
  .how-it-works-card-wrap {
    flex-direction: column;
  }
  .how-it-works-card {
    margin: 5px;
    margin-left: 25px;
    margin-right: 25px;
    padding: 10px;
  }
  .how-it-works-card-head {
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
  .how-it-works-card-head h5 {
    font-size: 22px;
  }
  .how-it-works-img {
    width: 90px;
  }
  .advisor-container {
    flex-direction: column;
    align-items: center;
  }
  .advisor-container p {
    padding-top: 0px;
    font-family: matter;
    font-size: 20px;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .querySection {
    padding: 10px 15px 25px 10px;
  }
  .query-cards-container {
    flex-direction: column;
  }
  .commonHeading p {
    font-family: 'matterMedium';
    line-height: 26px;
  }
  .query-card-header {
    gap: 10px;
  }
  .query-cards-container .query-card {
    border-radius: 10px;
    padding: 1rem;
    border: 2px solid #0c286a;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 875px) {
  .querySection {
    padding: 10px 15px 25px 10px;
  }
  .query-cards-container {
    flex-direction: column;
  }
  .commonHeading p {
    font-family: 'matterMedium';
    line-height: 26px;
  }
  .query-card-header {
    gap: 10px;
  }
  .query-cards-container .query-card {
    border-radius: 10px;
    padding: 1rem;
    border: 2px solid #0c286a;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 776px) {
  .querySection {
    padding: 10px 15px 25px 10px;
  }
  .commonHeading p {
    font-family: 'matterMedium';
    line-height: 26px;
  }
  .query-cards-container {
    flex-direction: column;
  }
  .query-card-header {
    gap: 10px;
  }
  .query-cards-container .query-card {
    border-radius: 10px;
    padding: 1rem;
    border: 2px solid #0c286a;
    width: 100%;
    max-width: 100%;
  }
}
