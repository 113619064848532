/* Price Section */
.price1 {
  /* background: linear-gradient(96.82deg,
      rgba(121, 130, 230, 0.12) 0%,
      rgba(121, 130, 230, 0) 100%); */
  padding: 60px 0 40px;
}

.price-body {
  background-color: #eff2ff;
  padding: 10px;
  padding-top: 45px;
}

.numbers-card-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 5px;
  row-gap: 5px;
  margin-top: 3rem;
}

.numbers-card {
  background-color: White;
  padding: 20px;
}

.numbers-card-header h5 {
  font-weight: 500;
  font-size: 28px;
}
.price-header {
  text-align: center;
}
.price-header-p {
  font-family: 'matterThin';
  color: #112c6d;
  text-align: center;
}
@media (max-width: 1330px) {
  .numbers-card-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .price-header {
    font-size: 30px;
  }
  .numbers-card-wrap {
    grid-template-columns: repeat(1, 1fr);
    padding: 10px;
    row-gap: 8px;
    margin-top: 1rem;
  }
  .numbers-card {
    padding: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .numbers-card p {
    padding-top: 0px;
    font-size: 16px;
  }
  .numbers-card-header {
    display: flex;
    align-items: center;
  }
  .numbers-card-header h5 {
    font-size: 22px;
  }
  .price-header-p {
    padding-top: 0px;
    font-size: 18px;
  }
}
