/* brand Section */
.hero {
  background-color: #003cc5;
  padding: 100px 0px 0px 5px;
}

.hero .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

/* left section */
.hero-left-section {
  width: 50%;
}

.hero-left-section h2 {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
  line-height: 60px !important;
  /* letter-spacing: -1.5px; */
  font-size: 48px;
}

.hero-left-section p {
  margin-bottom: 40px;
  color: white;
  font-size: 16px;
}

.left-btn-container {
  column-gap: 20px;
  margin-bottom: 20px;
  min-width: 180px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.hero-button-green {
  /* border: 1px solid #59ffdc !important; */
  height: 60px;
  padding: 30px !important;
  font-size: 16px !important;
  color: Black !important;
  background-color: #59ffdc !important;
  border-radius: 6px !important;
  overflow: hidden;
  text-transform: none !important;
  font-family: 'matterMedium' inherit !important;
}

.hero-button-white {
  color: #fff !important;
  border: 1px solid white !important;
  padding: 0 24px !important;
  border-radius: 6px !important;
  display: inline-flex;
  height: 60px !important;
  text-transform: none !important;
  padding: 30px !important;
  font-size: 16px !important;
  font-family: 'matterMedium' inherit !important;
}

.hero-right-section {
  width: 60%;
  margin-left: 30px;
  display: flex;
  justify-content: center;
}
.hero-right-section video {
  height: 385px !important;
  border-radius: 20px;
}
.heroImg-mobile video {
  /* height: 250px !important; */
  border-radius: 20px;
}
.hero-cards-container {
  margin-top: 20px;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  column-gap: 1%;
  align-content: center;
  justify-content: space-between;
}

.hero-cards-container .hero-card {
  border-radius: 10px;
  padding: 1rem;
  border: 2px solid white;
  max-width: 100%;
  height: 160px;
}

.hero-card p {
  padding-left: 0px !important;
  font-size: 20px;
  color: white;
}

.hero .hero-logos {
  display: grid;
  grid-template-columns: repeat(7, 125px);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  grid-gap: 20px;
  column-gap: 40px;
  /* margin-bottom: 28px; */
  padding-bottom: 75px;
}

.hero-note {
  margin-top: 3rem;
  margin-bottom: 10px;
  color: white;
  font-size: 16px;
}

/* .userBannerSlicker {
  display: none;
} */

.heroImg-mobile {
  display: none;
}

@media (max-width: 1330px) {
}

@media (max-width: 820px) {
  /* .hero-cards-container {
    display: grid !important;
  } */
}
@media (max-width: 992px) {
  .hero {
    padding: 100px 0px 15px 10px;
  }
  .hero .container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
  }

  .hero-logos {
    display: none !important;
  }

  .hero-left-section {
    width: 100%;
    /* padding: 20px; */
  }

  .hero-right-section {
    display: none;

    /* width: 100%;
    margin-left: 0px; */
  }
  .heroImg-mobile {
    display: contents;
    width: 100%;
    /* margin-left: 0px; */
    margin-left: -10px;
    margin-bottom: 1rem;
  }

  .heroImg-mobile .hero-cards-container {
    display: none !important;
  }

  .hero-card p {
    line-height: 20px;
  }
  .hero-left-section h2 {
    margin-top: 0px;
    font-size: 34px;
    padding-left: 10px;
    line-height: 40px !important;
    margin-bottom: 0px;
  }
  .hero-left-section p {
    margin-bottom: 1rem;
  }
  .left-btn-container {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    align-items: stretch;
    row-gap: 15px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 100px 0px 15px 10px;
  }

  .slick-slide img {
    width: 90px !important;
    margin: 5px;
  }
  .hero-logos {
    display: none !important;
  }
  .hero-button-green {
    width: 100%;
  }
  .hero-button-white {
    width: 100%;
  }
  .hero .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hero-left-section {
    width: 100%;
    /* padding: 20px; */
  }

  .hero-left-section h2 {
    margin-top: 0px;
    font-size: 30px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 36px !important;
    margin-bottom: 0px;
  }
  .hero-cards-container .hero-card {
    padding: 5px;
    height: 140px;
  }
  .hero-left-section p {
    margin-right: 10px;
    line-height: 30px;
    margin-bottom: 2rem;
  }
  .hero-right-section {
    display: none;
  }
  .desk-top-view {
    display: none !important;
  }
  .hero-cards-container {
    margin-right: 20px;
    margin-left: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 10px;
  }
  .hero-card h4 {
    font-size: 25px !important;
  }
  .hero-card img {
    width: 30px;
    margin-bottom: -5px;
  }
  .hero-card p {
    margin-bottom: 0px;
    margin-right: 0px;
    line-height: 20px;
    font-size: 18px;
    font-family: 'matterThin';
  }
  .heroImg-mobile {
    display: contents;
    width: 100%;
    margin-left: -10px;
    margin-bottom: 2rem;
  }
  .heroImg-mobile .hero-cards-container {
    display: grid !important;
  }
  .left-btn-container {
    align-items: stretch;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .hero-note-container {
    margin-top: 0px;
  }
  .hero-note {
    margin-top: 0px;
    font-family: 'matter';
  }
}

.testFonts {
  font-family: 'matterSemiBold';
}