/* Only whats app Section */
.onlyApp-background {
  background-color: #f1f8f2;
}
.onlyWhatsApp {
  padding: 10px;
}
.onlyWhatsApp .commonHeading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.onlyWhatsApp .commonHeading p {
  /* font-family: 'matterMedium'; */
  padding-top: 0rem;
  color: #0c286a;
}
.onlyWhatsApp .commonHeading h2 {
  /* font-size: 50px; */
  color: #0c286a;
}

.onlyWhatsApp-cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 25px;
  justify-content: space-around;
}

.onlyWhatsApp-cards .card {
  background-color: white;
  min-height: 250px;
  border-radius: 15px !important;
  padding: 2.25rem;
  width: 30%;
  border: 2px solid #0c286a;
}

.onlyWhatsApp-cards .card p {
  color: #0c286a;
}
.onlyWhatsApp-cards .card h6 {
  color: #0c286a;
  font-family: 'matter';
  font-size: 28px;
}

.btn-link-whatsapp {
  margin-top: 10px;
  color: #9fdfff;
}
@media (max-width: 1330px) {
  .downloadScanImage {
    right: 0;
  }
}
.whatsapp-mobile-header {
  display: none;
}
.whatsapp-header {
  display: block;
}
@media (max-width: 992px) {
  .onlyWhatsApp-cards {
    flex-wrap: wrap;
  }

  .onlyWhatsApp .commonHeading h2 {
    /* padding-left: 20px; */
    font-size: 32px;
    line-height: 40px !important;
  }
  .onlyWhatsApp .commonHeading p {
    padding-left: 15px !important;
    margin-top: 1rem;
    line-height: 25px;
  }
  .whatsapp-mobile-header {
    display: block;
  }
  .whatsapp-header {
    display: none;
  }
  .onlyWhatsApp-cards .card {
    padding: 24px;
    width: 100%;
    border: none !important;
  }

  .card {
    border: none !important;
  }
}

@media (max-width: 768px) {
  .whatsapp-header {
    display: none;
  }
  .onlyWhatsApp .commonHeading h2 {
    /* padding-left: 20px; */
    font-size: 32px;
    line-height: 40px !important;
  }
  .whatsapp-mobile-header {
    display: block;
  }
  .onlyWhatsApp .commonHeading p {
    /* padding-left: 20px !important; */
    margin-top: 1rem;
    line-height: 25px;
    font-family: 'matterThin';
    font-size: 18px;
  }

  .onlyWhatsApp-cards .card h3 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 23px;
  }

  .onlyWhatsApp-cards .card p {
    font-size: 16px;
    line-height: 24px;
  }
}
