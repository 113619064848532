.flexipai-body {
  background-color: #fbfbfb;
  padding: 20px;
}
.flexipay-header {
  display: flex;
  justify-content: space-between;
}
.flexipay-header-right img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.flexipay-header-right {
  display: flex;
}
.flexipay-card-wrap {
  display: flex;
  gap: 3%;
  margin-top: 1.5rem;
}

.flexi-halfCard-wrap {
  display: flex;
  gap: 3%;
}
.flexi-quarterly {
  width: 60%;
  padding: 10px;
  background-color: #ffe2f2;
}
.flexi-halfyearly {
  width: 100%;
  padding: 10px;
  background-color: #fff4ec;
}
.flexi-yearly {
  width: 100%;
  padding: 10px;
  background-color: #eff2ff;
  height: fit-content;
}

.flexi-card-header {
  width: 100%;
  grid-template-columns: 1fr 1fr;
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.flexi-card-headright {
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
}

.flexi-card-headright p {
  color: black;
  padding-top: 0px;
  font-size: 16px;
  align-items: center;
}

.flexi-card-headright span {
  display: flex;
  font-family: matter;
  align-items: center;
  gap: 10px;
}

.flexi-card-headright span p {
  color: black;
}

.flexi-card-body {
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  padding: 10px;
  justify-items: center;
  align-items: center;
}

.flexi-card-body p {
  padding-top: 0px;
  color: black;
  font-family: 'matter';
}

.card-duration-p {
  font-family: 'matterBold' !important;
}
.card-percent-p {
  font-family: 'matterSemiBold' !important;
}
.card-label-p {
}
.flexi-note p {
  font-family: 'matter';
  color: black;
}
.divider-css {
  border-color: #2d2b29;
  border-width: 1px;
  margin-bottom: 2px;
  margin-top: 2px;
}

@media (max-width: 768px) {
  .flexipay-header {
    flex-direction: column-reverse;
  }
  .flexipay-header h2 {
    font-size: 32px;
  }
  .flexi-card-header h6 {
    font-size: 20px;
  }
  .flexi-card-body p {
    font-size: 16px;
  }
  .flexipay-header-right img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  .flexi-quarterly {
    margin: 5px;
    width: 100%;
  }
  .flexi-halfyearly {
    margin: 5px;
  }
  .flexi-yearly {
    margin: 5px;
  }
  .flexipay-card-wrap {
    flex-direction: column;
  }
  .flexi-halfCard-wrap {
    flex-direction: column;
  }
  .flexi-note p {
    font-size: 14px;
  }
}

@media (max-width: 630px) {
}

@media (max-width: 550px) {
}
@media (max-width: 360px) {
}
